.reactSelect {
  input {
    margin-bottom: 0 !important;
    border: none !important;
    height: initial !important;
    box-shadow: none !important;
    border-radius: initial !important;
    line-height: initial !important;
  }
}
