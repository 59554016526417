.container {
  display: grid;
  grid-template-columns: 1fr auto 1fr 80px;
  align-items: center;
}

.inputItem {
  text-align: center;

  input {
    text-align: center;
  }

  button {
    border: none;
    background: none;
    outline: none;
    color: #0088cc;

    &:hover {
      text-decoration: underline;
    }
  }
}
