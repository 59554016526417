/**
 * The main stylesheet
 *
 * Imports the other stylesheets and generates app.css for the app to import.
 */
.upper {
  text-transform: uppercase;
}
.left-offset-0 {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.right-offset-10 {
  margin-right: 10px !important;
}
.bottom-offset-10 {
  margin-bottom: 10px !important;
}
.top-offset-10 {
  margin-top: 10px !important;
}
.padding10 {
  padding: 10px;
}
.vtop {
  vertical-align: top;
}
.vbottom {
  vertical-align: bottom;
}
a {
  cursor: pointer;
}
.spacer {
  margin-bottom: 10px;
}
.circle-20 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  text-align: center;
}
.circle-20 .icon {
  font-size: 10px;
  line-height: 20px;
}
.circle-30 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  text-align: center;
  box-shadow: 1px 1px 3px #525252;
}
.circle-30 .icon {
  font-size: 15px;
  line-height: 30px;
}
.circle-50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #fff;
  text-align: center;
  box-shadow: 2px 2px 5px #525252;
}
.circle-50 .icon {
  font-size: 22px;
  line-height: 50px;
}
.circle-50-thick {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5) inset, 0 1px 2px rgba(0,0,0,0.5);
  border: 10px solid #fff;
  text-align: center;
}
.circle-50-thick .icon {
  font-size: 22px;
  line-height: 50px;
}
.pill {
  position: relative;
  font-size: 0.85em;
  padding: 3px 10px;
  margin-top: 3px;
  border-radius: 5px;
  color: #ccc;
  border: none;
  box-shadow: 0 2px 4px rgba(119,119,119,0.5);
  text-align: left;
  margin-right: 10px;
  background-color: #97bdd4;
  border-radius: 4px 10px;
}
.pill.image {
  overflow: visible;
  margin: 20px 10px 20px 0;
  padding-left: 45px;
}
.pill.image img {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -10px;
  left: -5px;
  border-radius: 50%;
  border: 2px solid #bf86ff;
}
.pill .circle-20 {
  border: none;
  float: right;
  margin-left: 5px;
}
.pointer {
  cursor: pointer;
}
.unselectable {
  user-select: none;
}
.comma-separated::before {
  content: ',';
  margin-right: 7px;
}
[class^="circle-"].gray {
  border-color: #c2c2c2;
}
@media (max-width: 2979px) and (min-width: 768px) {
  .row-fluid.no-space [class*="span"] {
    margin-left: 0%;
  }
  .row-fluid.no-space [class*="span"]:first-child {
    margin-left: 0;
  }
  .row-fluid.no-space .controls-row [class*="span"] + [class*="span"] {
    margin-left: 0%;
  }
  .row-fluid.no-space .span12 {
    width: 99.99999999999999%;
  }
  .row-fluid.no-space .span11 {
    width: 91.66666666666666%;
  }
  .row-fluid.no-space .span10 {
    width: 83.33333333333331%;
  }
  .row-fluid.no-space .span9 {
    width: 74.99999999999999%;
  }
  .row-fluid.no-space .span8 {
    width: 66.66666666666666%;
  }
  .row-fluid.no-space .span7 {
    width: 58.33333333333333%;
  }
  .row-fluid.no-space .span6 {
    width: 49.99999999999999%;
  }
  .row-fluid.no-space .span5 {
    width: 41.66666666666666%;
  }
  .row-fluid.no-space .span4 {
    width: 33.33333333333333%;
  }
  .row-fluid.no-space .span3 {
    width: 24.999999999999996%;
  }
  .row-fluid.no-space .span2 {
    width: 16.666666666666664%;
  }
  .row-fluid.no-space .span1 {
    width: 8.333333333333332%;
  }
  .row-fluid.no-space .offset12 {
    margin-left: 99.99999999999999%;
  }
  .row-fluid.no-space .offset12:first-child {
    margin-left: 99.99999999999999%;
  }
  .row-fluid.no-space .offset11 {
    margin-left: 91.66666666666666%;
  }
  .row-fluid.no-space .offset11:first-child {
    margin-left: 91.66666666666666%;
  }
  .row-fluid.no-space .offset10 {
    margin-left: 83.33333333333331%;
  }
  .row-fluid.no-space .offset10:first-child {
    margin-left: 83.33333333333331%;
  }
  .row-fluid.no-space .offset9 {
    margin-left: 74.99999999999999%;
  }
  .row-fluid.no-space .offset9:first-child {
    margin-left: 74.99999999999999%;
  }
  .row-fluid.no-space .offset8 {
    margin-left: 66.66666666666666%;
  }
  .row-fluid.no-space .offset8:first-child {
    margin-left: 66.66666666666666%;
  }
  .row-fluid.no-space .offset7 {
    margin-left: 58.33333333333333%;
  }
  .row-fluid.no-space .offset7:first-child {
    margin-left: 58.33333333333333%;
  }
  .row-fluid.no-space .offset6 {
    margin-left: 49.99999999999999%;
  }
  .row-fluid.no-space .offset6:first-child {
    margin-left: 49.99999999999999%;
  }
  .row-fluid.no-space .offset5 {
    margin-left: 41.66666666666666%;
  }
  .row-fluid.no-space .offset5:first-child {
    margin-left: 41.66666666666666%;
  }
  .row-fluid.no-space .offset4 {
    margin-left: 33.33333333333333%;
  }
  .row-fluid.no-space .offset4:first-child {
    margin-left: 33.33333333333333%;
  }
  .row-fluid.no-space .offset3 {
    margin-left: 24.999999999999996%;
  }
  .row-fluid.no-space .offset3:first-child {
    margin-left: 24.999999999999996%;
  }
  .row-fluid.no-space .offset2 {
    margin-left: 16.666666666666664%;
  }
  .row-fluid.no-space .offset2:first-child {
    margin-left: 16.666666666666664%;
  }
  .row-fluid.no-space .offset1 {
    margin-left: 8.333333333333332%;
  }
  .row-fluid.no-space .offset1:first-child {
    margin-left: 8.333333333333332%;
  }
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  box-sizing: border-box;
  height: 27px;
  border: 1px solid #e6e6e6;
  border-top: rgba(92,92,92,0.64) 1px solid;
  background-color: rgba(255,255,255,0.65);
}
select.ng-invalid,
textarea.ng-invalid,
input[type="text"].ng-invalid,
input[type="password"].ng-invalid,
input[type="datetime"].ng-invalid,
input[type="datetime-local"].ng-invalid,
input[type="date"].ng-invalid,
input[type="month"].ng-invalid,
input[type="time"].ng-invalid,
input[type="week"].ng-invalid,
input[type="number"].ng-invalid,
input[type="email"].ng-invalid,
input[type="url"].ng-invalid,
input[type="search"].ng-invalid,
input[type="tel"].ng-invalid,
input[type="color"].ng-invalid,
.uneditable-input.ng-invalid {
  border-color: #df1b17 !important;
  background: #fff8f1 !important;
}
select.ng-invalid:focus,
textarea.ng-invalid:focus,
input[type="text"].ng-invalid:focus,
input[type="password"].ng-invalid:focus,
input[type="datetime"].ng-invalid:focus,
input[type="datetime-local"].ng-invalid:focus,
input[type="date"].ng-invalid:focus,
input[type="month"].ng-invalid:focus,
input[type="time"].ng-invalid:focus,
input[type="week"].ng-invalid:focus,
input[type="number"].ng-invalid:focus,
input[type="email"].ng-invalid:focus,
input[type="url"].ng-invalid:focus,
input[type="search"].ng-invalid:focus,
input[type="tel"].ng-invalid:focus,
input[type="color"].ng-invalid:focus,
.uneditable-input.ng-invalid:focus {
  box-shadow: 0 0 6px #d59392 !important;
}
.filter {
  margin-bottom: -3px;
  text-align: right;
  background: linear-gradient(to bottom, rgba(184,184,184,0.74), rgba(220,220,220,0.92));
  margin: 0;
  padding: 3px 15px 3px 5px;
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 900;
  border-bottom: solid 1px #8b8b8b;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.25);
}
.filter.left {
  top: 50px;
  right: unset;
  left: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  padding: 3px 5px 3px 15px;
}
.filter.left input[type="text"] {
  padding-left: 5px;
  margin-left: 5px;
  width: 200px !important;
  transition: 0.25s linear;
  border-radius: 0;
  border-left: solid 1px #a9a9a9;
}
.filter.left input[type="text"]:first-of-type {
  border-radius: 4px 4px 10px 4px;
}
.filter.btn-group .icon-users,
.filter.btn-group .icon-search,
.filter.btn-group .icon-cloud,
.filter.btn-group .icon-tag,
.filter.btn-group .icon-edit,
.filter.btn-group .icon-sort-alt-up,
.filter.btn-group .filter-icon,
.filter.btn-group .icon-flag,
.filter.btn-group .icon-puzzle {
  position: relative;
  top: 4px;
  left: 22px;
  color: #696969;
  pointer-events: none;
  padding: 3px;
  margin-left: -20px;
  font-size: 14px;
}
.filter select {
  transition: 0.25s linear;
  padding-left: 20px;
  padding-top: 0px;
  width: 15px !important;
  -webkit-appearance: none;
  margin-top: 0px;
  margin-bottom: 1px;
  border-radius: 0px;
  line-height: 25px;
  border-left: solid 1px #a9a9a9;
}
.filter select:focus {
  width: 140px !important;
}
.filter input[type="text"] {
  padding-left: 20px;
  width: 15px !important;
  transition: 0.25s linear;
  margin-top: 0px;
  margin-bottom: 1px;
  border-radius: 0;
  border-left: solid 1px #a9a9a9;
}
.filter input[type="text"]:first-of-type {
  border-radius: 4px 0 0 10px;
}
.filter input[type="text"]:focus {
  width: 200px !important;
}
input[type="text"],
input[type="password"],
input[type="number"] {
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}
textarea {
  height: 72px;
}
.control-group:last-child {
  margin-bottom: 0;
}
textarea.rows5 {
  height: 110px;
}
textarea.rows10 {
  height: 220px;
}
.validate {
  border: 1px solid transparent;
}
.validate.required {
  border: 1px solid #f00;
}
.validate.required .mce-edit-area {
  background: #fff8f1 !important;
}
label.inline {
  display: inline-block;
}
label.vertical {
  margin: 0;
}
a.checkbox,
button.checkbox {
  padding: 0 7px 0 0;
  margin-top: 1px;
  display: inline-block;
  width: 22px;
  color: #333;
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
}
a.checkbox [class^="icon-"],
button.checkbox [class^="icon-"] {
  vertical-align: middle;
}
a.checkbox:hover,
button.checkbox:hover {
  text-decoration: none;
}
a.checkbox.colored .icon-check,
button.checkbox.colored .icon-check {
  color: #008000;
}
a.checkbox.colored .icon-check-empty,
button.checkbox.colored .icon-check-empty {
  color: #808080;
}
.select2-container {
  margin-bottom: 10px;
}
.select2-container.ng-invalid .select2-choice,
.select2-container.ng-invalid .select2-choices {
  border-color: #df1b17 !important;
}
.input-append .add-on {
  background: none;
  border: none;
  text-shadow: none;
}
.btn-group .btn {
  font-size: 12px;
}
.btn-group .btn-small {
  font-size: 12px;
}
.btn-group .btn-mini {
  font-size: 10.5px;
}
.btn {
  font-size: 14px;
  box-sizing: border-box;
  padding: 3px 8px;
  box-shadow: none;
  text-shadow: none;
  font-weight: bold;
}
.sort-handle {
  cursor: all-scroll;
}
.btn-small {
  padding: 3px 7px 2px;
  height: 26px;
  font-size: 12px;
}
.btn-small [class*="icon"] {
  margin-right: 3px;
  margin-top: 1px;
}
.btn-mini {
  font-size: 10.5px;
}
.btn-icon {
  background-color: #ebebeb;
  color: #333;
  padding: 7px 9px 6px;
  line-height: 14px;
  height: 30px;
}
.btn-icon [class*="icon"] {
  margin: 0;
}
.btn-icon:hover {
  background-color: #ebebeb;
  border-color: #adadad;
}
.btn-group.open .btn-icon {
  border-color: #adadad;
  background: #ebebeb;
  box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
}
.btn-toggle {
  background: url("/src/images/checkbox-off.png") no-repeat;
  width: 34px;
  height: 18px;
  padding: 0;
  border: none;
}
.btn-toggle.active {
  background: url("/src/images/checkbox-on.png") no-repeat;
  border: none;
}
#login {
  position: relative;
  background: rgba(199,199,199,0.4);
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px 20px;
  box-shadow: 0 0 10px #000;
}
#login .footer {
  text-align: right;
  padding: 0 10px;
  font-family: triunity condensed;
}
#login .footer a {
  color: #fff;
}
#login .footer .btn {
  border-radius: 4px 10px;
}
.login .row-fluid {
  margin-bottom: 5px;
}
.login_pack {
  border-radius: 10px 40px;
  padding: 20px 40px 50px;
  width: 250px;
  background-image: linear-gradient(to bottom, rgba(245,245,245,0.34), rgba(194,194,194,0), rgba(240,240,240,0.29));
  margin: 0 auto 10px;
  color: #fff;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
}
.header {
  Color: #fff;
  font-size: 18px;
}
.header h1 {
  font-size: 40px;
  font-family: triunity condensed;
}
.pane {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.pane > .top-buttons {
  box-sizing: border-box;
  height: 25px;
  line-height: 25px;
  padding: 0 15px;
  background: #ccc;
}
.pane > .top-buttons .button,
.pane > .top-buttons button,
.pane > .top-buttons .btn {
  margin-right: 10px;
}
.pane > .top-buttons .button.active,
.pane > .top-buttons button.active,
.pane > .top-buttons .btn.active {
  color: #fff;
  background: linear-gradient(to bottom, #777, #9e9e9e, #585858);
}
.pane > .body,
.pane > ng-include > .body,
.pane > ng-include > .wrapper > .body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-sizing: content-box;
  padding: 15px;
  margin: 0;
  transition: all 0.2s;
  z-index: 9;
}
.pane > .body > .head,
.pane > ng-include > .body > .head,
.pane > ng-include > .wrapper > .body > .head {
  background: linear-gradient(to bottom, #777, #595959, #2e2e2e);
  border-bottom: 1px solid #484848;
  color: #fff;
  padding: 3px 15px;
  font-family: triunity condensed, sans-serif;
  font-size: 20px;
  height: 42px;
  line-height: 30px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
}
.pane > .body > .head .buttons,
.pane > ng-include > .body > .head .buttons,
.pane > ng-include > .wrapper > .body > .head .buttons {
  position: absolute;
  right: 16px;
  bottom: 0px;
}
.pane > .body > .head .buttons .btn,
.pane > ng-include > .body > .head .buttons .btn,
.pane > ng-include > .wrapper > .body > .head .buttons .btn {
  padding: 0 7px;
}
.pane.with-widgets > .body,
.pane.with-widgets > ng-include > .body,
.pane.with-widgets > ng-include > .wrapper > .body {
  bottom: 150px;
}
.list-pane {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.list-pane > .head {
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
  color: #555;
  font-size: 30px;
  padding: 0px 15px;
  border-bottom: 1px solid #7a7a7a;
  text-shadow: 1px 1px 3px #c2c2c2;
}
.list-pane > .head + .items {
  top: 32px;
}
.list-pane > .items {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.list-pane > .items > .item {
  height: 90px;
  background: linear-gradient(to bottom, #e4e3e3, #fff);
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #ececec;
  display: table;
  width: 100%;
  font-size: 1.2em;
  text-shadow: 1px 1px 2px #b8b8b8;
}
.list-pane > .items > .item > * {
  display: table-cell;
  vertical-align: middle;
}
.list-pane > .items > .item.done {
  background: linear-gradient(to bottom, #c1c0c0, #e4e5e6);
  color: #a1a2a3;
}
.list-pane > .items > .item.active {
  background: linear-gradient(to bottom, #090909, #505052 40%, #464547);
  color: #fff;
  text-shadow: 1px 1px 2px #868686;
}
.grid {
  border-radius: 4px 20px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
  border: solid 1px;
  border-top-color: #d5d5d5;
  border-left-color: #aeaeae;
  border-right-color: #b6b6b6;
  border-bottom-color: #717171;
  overflow: hidden;
}
.grid table.list tbody tr .icons {
  white-space: nowrap;
}
.icon::before,
.icon::after {
  box-sizing: content-box;
}
.dropdown-menu > li > a {
  padding: 3px 10px;
}
.dropdown-menu > li > a [class^="icon-"] {
  margin-right: 4px;
}
#notifications {
  top: 55px;
  z-index: 10000;
}
#notifications .dr-notification {
  background-color: rgba(245,245,245,0.85);
  color: #333;
  max-height: none;
  border-radius: 2px;
  border: 1px solid rgba(88,88,88,0.85);
  box-shadow: 3px 3px 8px #464646;
}
#notifications .dr-notification-title {
  color: #333;
  line-height: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
#notifications .dr-notification-content {
  padding-left: 75px;
}
#notifications .dr-notification-image {
  border: none;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -20px;
}
#notifications .dr-notification-image i {
  padding-top: 0px;
}
#notifications .dr-notification-close-btn {
  padding: 0 5px;
  right: 2px;
  border: none;
  color: #383838;
  background: none;
}
#notifications .dr-notification-close-btn i {
  padding: 0;
}
.file-upload {
  margin-bottom: 10px;
}
.file-upload .btn-inverse {
  position: relative;
  overflow: hidden;
  float: left;
  margin-top: 6px;
}
.file-upload .btn-inverse input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: alpha(opacity=0);
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}
.file-upload .files {
  margin-top: 5px;
  padding: 7px 5px;
  background: #fff;
}
.files .progress {
  margin-bottom: 8px;
  margin-top: 5px;
  height: 8px;
  border-radius: 0;
  border: 1px solid #c7c7c7;
}
.files .progress:last-child {
  margin-bottom: 0;
}
.files .btn {
  overflow: hidden;
  border-radius: 0;
  padding: 3px 4px 6px 4px;
  font-size: 12px;
  line-height: 16px;
  height: 22px;
  width: 22px;
  float: right;
}
.files .btn span {
  display: none;
}
.btn-upload {
  position: relative;
  overflow: hidden;
}
.btn-upload input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: alpha(opacity=0);
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}
@media screen {
  .file-upload input {
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
  }
}
#navigation,
#mainNavigation {
  position: absolute;
  right: 0;
  top: 50px;
  bottom: 20px;
  width: 0;
  background: #353535;
  z-index: 20000;
  overflow: hidden;
}
#navigation ul,
#mainNavigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
#navigation ul li,
#mainNavigation ul li {
  position: relative;
}
#navigation ul li:hover,
#mainNavigation ul li:hover,
#navigation ul li.opened,
#mainNavigation ul li.opened {
  background: #292929;
}
#navigation ul li:hover a,
#mainNavigation ul li:hover a,
#navigation ul li.opened a,
#mainNavigation ul li.opened a {
  color: #fff;
}
#navigation ul li.opened ul,
#mainNavigation ul li.opened ul {
  display: block;
  max-height: 400px;
  transition: max-height 500ms;
}
#navigation ul li ul,
#mainNavigation ul li ul {
  position: relative;
  background: #858585;
  max-height: 0;
  box-sizing: content-box;
  overflow: auto;
  margin: 0;
}
#navigation ul li ul li:hover,
#mainNavigation ul li ul li:hover {
  background: #585858;
}
#navigation ul li ul li a,
#mainNavigation ul li ul li a {
  color: #fdfdfd;
  border-bottom: 1px solid #6b6b6b;
}
#navigation ul a,
#mainNavigation ul a {
  color: #999;
  display: block;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #3a3a3a;
  text-decoration: none;
  padding: 0 20px;
  cursor: pointer;
}
#navigation ul a [class*="icon-"],
#mainNavigation ul a [class*="icon-"] {
  font-size: 22px;
  margin-right: 6px;
  line-height: 18px;
}
#navigation.opened,
#mainNavigation.opened {
  transition: 0.25s;
  width: 250px;
}
#navigation.closed,
#mainNavigation.closed {
  transition: 0.25s;
  right: -250px;
  opacity: 0;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -32px;
  width: 38px;
  height: 38px;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-shadow: 0 1px 1px #999;
  border: 8px solid #fff;
  border-radius: 50%;
  padding: 2px 2px 2px 3px;
  overflow: hidden;
  background: #fff;
}
.skills table.list td {
  border-right: dotted 1px #ccc;
}
.skills table.list td:last-child {
  border-right: none;
}
table.list {
  width: 100%;
}
table.list[class*="span"] {
  display: table;
}
table.list td {
  height: 30px;
  padding: 4px 14px;
  color: #333;
}
table.list td.vtop {
  vertical-align: top;
  padding-top: 5px;
}
table.list td.icons {
  padding: 4px 10px;
}
table.list td.icons .checkbox a {
  width: 14px;
  padding: 0;
}
table.list td .cell {
  display: inline-block;
  font-family: triunity condensed;
  padding: 2px 14px;
  vertical-align: middle;
}
table.list .label {
  border: solid 1px #ccc;
  font-weight: normal;
}
table.list thead > tr {
  background: #686868;
  font-weight: bold;
  font-size: 13px;
}
table.list thead > tr > td {
  font-size: 13px;
  padding: 2px 14px;
  color: #fff;
  height: 15px;
}
table.list thead.light > tr {
  background: linear-gradient(to bottom, #fff, #e6e6e6);
}
table.list thead.light > tr > td {
  color: #333;
}
table.list tbody tr {
  border-top: 1px solid #ccc;
  background: linear-gradient(to bottom, #e7e7e7 26%, #f1f1f1, #fff);
  margin-bottom: 1px;
  height: 30px;
}
table.list tbody tr:first-child {
  border-top: 1px solid #9e9e9e;
}
table.list tbody tr:hover {
  background: #fff;
  border-top: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
}
table.inner-list {
  border: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 4px 20px;
  overflow: hidden;
}
table.inner-list > thead > tr {
  background: #ccc;
}
table.inner-list > thead > tr > td {
  color: #fff;
  font-size: 12px;
  padding: 4px 10px;
}
.accordion {
  background: #fff;
  border: 1px solid #9e9e9e;
  border-bottom: 1px solid #fff;
  position: relative;
  border-radius: 4px 20px;
  overflow: hidden;
  margin-top: 3px;
}
.accordion > .title {
  background: #c9c9c9;
  background: #ccc;
  font-weight: bold;
  font-size: 13px;
  border-bottom: 1px solid #8b8b8b;
  color: #fff;
  border-radius: 4px 10px 0 0;
  box-shadow: inset -2px -3px 8px rgba(0,0,0,0.28);
  border-bottom: solid 1px rgba(0,0,0,0.51);
}
.accordion > .title > span {
  display: inline-block;
  padding: 8px;
}
.accordion > .title .buttons {
  float: right;
  padding-right: 8px !important;
  padding-top: 5px !important;
}
.accordion .accordion-heading {
  background: linear-gradient(to bottom, #e7e7e7, #f1f1f1 26%, #fff);
  position: relative;
}
.accordion .accordion-heading .buttons {
  float: right;
  margin-top: -5px;
}
.accordion .accordion-heading .buttons > *:first-child {
  margin-left: 0px;
}
.accordion .accordion-heading a {
  color: #333;
  text-decoration: none;
}
.accordion .accordion-heading .accordion-toggle {
  padding: 8px 8px;
  padding-left: 28px;
}
.accordion .accordion-heading .accordion-toggle::before {
  font-family: FontAwesome;
  content: '\e8c4';
  color: rgba(0,0,0,0.3);
  text-align: center;
  font-size: 16px;
  float: left;
  position: absolute;
  top: 7px;
  left: 10px;
}
.accordion .accordion-heading .cell {
  display: inline-block;
  vertical-align: top;
}
.accordion .accordion-heading table.list {
  border: none;
  margin: -4px 0 -10px -10px;
}
.accordion .accordion-heading table.list tbody tr,
.accordion .accordion-heading table.list tbody tr:hover {
  background: none;
  border: none;
}
.accordion .accordion-heading table.list td {
  vertical-align: top;
}
.accordion .accordion-group {
  margin-bottom: 0;
}
.accordion .accordion-group.closed .accordion-body {
  border-bottom: none;
}
.accordion .accordion-group.expanded .accordion-heading .accordion-toggle::before {
  content: '\e8c6';
}
.accordion .accordion-group.colapsed .accordion-heading .accordion-toggle::before {
  content: '\e8c4';
}
.accordion .accordion-body .content {
  padding: 5px;
}
.accordion .accordion-body .title {
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 0px;
  margin-top: 3px;
}
.accordion .accordion-group.no-body-padding .accordion-inner {
  padding: 0;
}
div.list {
  border-radius: 4px 20px 0 0;
  overflow: hidden;
  margin-bottom: -18px;
}
div.list > div.title {
  padding: 4px 8px;
  background: #ccc;
  font-weight: bold;
  font-size: 13px;
  color: #fff;
  line-height: 27px;
  padding-bottom: 15px;
}
div.list > div.title.light {
  background: linear-gradient(to bottom, #fff, #e6e6e6);
  color: #333;
}
div.list > div.title .buttons {
  float: right;
}
div.list > div.title .cell {
  display: inline-block;
}
div.list > div.list-row {
  border-top: 1px solid #8b8b8b;
  background: linear-gradient(to bottom, #e7e7e7 26%, #f1f1f1, #fff);
  position: relative;
}
div.list > div.list-row .cell {
  display: inline-block;
  font-family: triunity condensed;
  padding: 2px 14px;
  vertical-align: middle;
}
div.list > div.list-row .icons {
  margin-top: -8px;
  position: absolute;
  right: 5px;
  top: 50%;
}
.inline-list > .list-item {
  border-bottom: 1px solid #a5a5a5;
  padding: 5px;
}
.inline-item {
  float: left;
  margin-left: 10px;
}
.inline-item:first-child {
  margin-left: 0;
}
.linux .modal {
  background-color: #ccc;
}
.modal {
  top: 10%;
  z-index: 10010;
  background: url("/src/images/content-bg-rev.gif") repeat;
  border-radius: 4px 20px;
  overflow: hidden;
}
.modal.top {
  width: 300px;
  margin-left: -150px;
  padding-bottom: 0;
  zindex: 1500;
}
.modal.fade {
  animation-name: stretch;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: none;
  animation-play-state: running;
}
.modal .date-picker[style] {
  left: 10px !important;
}
.modal .modal-content {
  background: none;
}
.modal .modal-body {
  background: linear-gradient(to bottom, #fff 90%, #cacaca);
  padding: 10px;
}
.modal .modal-body.padding {
  padding: 10px;
}
.modal .modal-body .gtky {
  background: url("/src/images/gtky.jpg") no-repeat top right;
  background-size: cover;
  min-height: 100px;
  padding: 25px 240px 15px 30px;
  margin: -10px;
  font-size: 0;
}
.modal .modal-body .row-label {
  font-weight: bold;
  clear: left;
}
.modal span.cat.icon {
  padding: 4px;
  width: 15px;
  border-radius: 50%;
  color: #fff;
  box-shadow: inset 2px 0 5px rgba(0,0,0,0.48);
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}
.modal .body.viewer {
  height: 100%;
}
.modal .body.viewer iframe {
  width: 100%;
  height: calc(100% - 50px);
}
.modal .body.viewer .question iframe {
  width: auto;
}
.modal .viewerButtons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9999;
  background: #ccc;
  padding: 5px;
}
.modal .viewerButtons .buttons.btn-group {
  border: 5px solid rgba(255,255,255,0.28);
  border-radius: 5px 15px;
  box-shadow: 0 0 5px rgba(0,0,0,0.38);
}
.modal .completed {
  position: absolute;
  right: 5%;
  top: 5%;
  color: #424242;
  padding: 5px 15px;
  font-family: triunity condensed, sans-serif;
  border: 5px solid rgba(255,255,255,0.28);
  border-radius: 5px 15px;
  box-shadow: 0 0 5px rgba(0,0,0,0.38);
  background: rgba(255,255,255,0.71);
  font-size: 12px;
  text-align: center;
  line-height: 12px;
}
.modal .modal-header {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 0;
  padding: 9px 15px;
}
.modal .modal-header h3 {
  font-weight: normal;
  color: #fff;
  font-size: 18px;
  width: 80%;
  line-height: 20px;
  margin-bottom: 0;
}
.modal .modal-header .buttons {
  position: absolute;
  right: 10px;
  top: 5px;
  text-align: right;
}
.modal .modal-header .btn {
  border: 1px solid #5e5e5e;
  border-bottom: 1px solid #9c9c9c;
}
.modal .modal-header .btn-default {
  background: linear-gradient(to bottom, #777, #595959, #2e2e2e);
  color: #fff;
}
.modal .modal-header .btn-group {
  margin: 0 4px;
}
.modal .modal-header .dropdown-menu {
  text-align: left;
}
.modal .modal-header .title {
  display: block;
  margin-right: 120px;
}
.modal .modal-footer {
  border-top: 1px solid #bbb;
  background: none;
  margin-bottom: -20px;
}
.modal .modal-footer .placeholder-info {
  float: left;
  height: 18px;
  color: rgba(255,255,255,0.6);
}
.modal .block div.header {
  background: #e8e8e8;
  border-top: 1px solid #808080;
  font-weight: bold;
  padding: 5px 20px 3px 10px;
  border-radius: 4px 10px;
  line-height: 16px;
  margin: 0 5px;
  Color: #626262;
  font-size: 14px;
}
.modal .block div.body {
  padding: 5px;
}
.modal .block div.body .pill {
  margin: 2px 0 0 0;
}
.modal .block div.body .pill:first-child {
  margin-top: 0;
}
.modal .block h1.title {
  background: #eee;
  font-style: italic;
  padding: 3px;
  border-bottom: 1px solid #cecece;
  font-weight: bold;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}
.modal .padding {
  padding: 10px;
}
.modal .padding5 {
  padding: 5px;
}
.modal .full-width {
  display: block;
}
.modal .heading {
  background: linear-gradient(to left, #fff, #e6e6e6);
  padding: 5px 15px;
  border-bottom: 1px solid #808080;
  margin-bottom: 5px;
  font-weight: bold;
}
.modal h1 {
  font-size: 22px;
  line-height: 22px;
  padding: 0 10px 3px 10px;
  margin: 0 -10px 10px -10px;
  border-bottom: 1px solid #a8a8a8;
}
.modal h2 {
  font-size: 20px;
  line-height: 20px;
  padding: 0 10px 3px 10px;
  margin: 0 -10px 10px -10px;
  border-bottom: 1px solid #a8a8a8;
}
.modal h5 {
  font-size: 15px;
  color: #7f7f7f;
  line-height: 27px;
  font-family: triunity condensed;
  margin: 0;
  padding: 0 15px;
}
.modal .holder {
  padding: 10px;
  margin-bottom: 10px;
}
.modal .filter {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: -10px;
}
.modal.wide {
  width: 786px;
  margin-left: -400px;
}
.modal.full-screen {
  left: 20px;
  right: 20px;
  top: 20px !important;
  bottom: 20px;
  margin-left: 0;
  width: auto;
}
.modal.full-screen .modal-body {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  top: 44px;
  max-height: none;
  height: auto !important;
}
.modal.full-screen .modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal.tall .modal-body {
  max-height: 600px;
  min-height: 500px;
}
@media (max-width: 800px) {
  .modal.wide {
    width: auto;
    margin: 0;
  }
}
@media (max-height: 900px) {
  .modal.tall.fade.in {
    top: 5%;
  }
  .modal.tall .modal-body {
    max-height: 600px;
  }
}
@media (max-height: 800px) {
  .modal.tall .modal-body {
    max-height: 500px;
  }
}
@media (max-height: 700px) {
  .modal.tall .modal-body {
    max-height: 400px;
  }
}
@media (max-height: 600px) {
  .modal.tall .modal-body {
    max-height: 300px;
  }
}
@media (max-width: 320px) {
  .modal.tall .modal-body {
    max-height: 200px;
  }
}
.richtext {
  min-height: 20px;
  background-color: rgba(255,255,255,0.65);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  margin-bottom: 10px;
  border-radius: 4px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0;
  border: 1px solid #999;
  border-top: rgba(0,0,0,0.64) 1px solid;
  overflow: hidden;
}
.richtext.editing {
  border-color: rgba(82,168,236,0.8);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(82,168,236,0.6);
}
.richtext.required {
  border-color: #df1b17 !important;
  background: #fff8f1 !important;
}
.richtext span.placeholder {
  color: #8f8f8f;
}
.richtext .display {
  margin: 10px;
  min-height: 115px;
  max-height: 305px;
  overflow-y: auto;
  overflow-x: hidden;
}
.richtext h1 {
  font-size: 16px;
  line-height: 16px;
  color: #555;
}
.richtext h2 {
  font-size: 14px;
  line-height: 14px;
  color: #555;
}
.richtext h3 {
  font-size: 13px;
  line-height: 13px;
  color: #555;
  font-style: italic;
  font-weight: bold;
}
.icon-picker {
  position: relative;
  display: inline-block;
}
.icon-picker [class*="icon"] {
  font-size: 22px;
  vertical-align: middle;
  text-align: center;
}
.icon-picker.required .preview {
  border-color: #df1b17 !important;
  background: #fff8f1 !important;
}
.icon-picker .preview {
  display: inline-block;
  text-align: center;
  line-height: 30px;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  padding: 6px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.icon-picker .picker {
  position: absolute;
  top: 32px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  padding: 0px;
  padding-top: 5px;
  border-radius: 4px;
  width: 355px;
  z-index: 1000;
}
.icon-picker .picker .picker-icon {
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  margin-left: 3px;
  margin-bottom: 3px;
  border-radius: 2px 5px;
  border: 1px solid #808080;
  cursor: pointer;
}
.icon-picker .picker .picker-icon:hover {
  border: 1px solid #808080;
}
.icon-1:before {
  content: "1";
}
.icon-2:before {
  content: "2";
}
.icon-3:before {
  content: "3";
}
.icon-4:before {
  content: "4";
}
.icon-5:before {
  content: "5";
}
.icon-6:before {
  content: "6";
}
.icon-7:before {
  content: "7";
}
.icon-8:before {
  content: "8";
}
.icon-9:before {
  content: "9";
}
.icon-0:before {
  content: "0";
}
.color-picker {
  position: relative;
  display: inline-block;
}
.color-picker.required .preview {
  border-color: #df1b17 !important;
  background: #fff8f1 !important;
}
.color-picker .preview {
  display: inline-block;
  text-align: center;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  padding: 6px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.color-picker .picker {
  position: absolute;
  top: 42px;
  left: -55px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  padding: 0;
  padding-top: 5px;
  border-radius: 4px 10px;
  width: 350px;
  z-index: 1000;
}
.color-picker .picker .picker-color {
  display: inline-block;
  text-align: center;
  width: 25px;
  height: 25px;
  margin-left: 2px;
  margin-bottom: -5px;
  border-radius: 2px 5px;
  border: 1px solid transparent;
  cursor: pointer;
}
.color-picker .picker .picker-color:hover {
  border: 1px solid #808080;
}
.colorpicker.dropdown-menu {
  width: 120px;
  min-width: inherit;
  padding: 5px;
  padding-bottom: 0;
}
.hint {
  border: 1px solid #f00;
  z-index: 2010;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100px;
  max-width: 300px;
  padding: 1px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  white-space: normal;
}
.hint .hint-inner {
  padding: 9px 14px;
}
.hint .arrow {
  border-width: 11px;
}
.hint .arrow,
.hint .arrow:after {
  border-width: 10px;
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.hint.top {
  margin-top: -10px;
}
.hint.top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0,0,0,0.25);
  bottom: -11px;
}
.hint.top .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.hint.bottom {
  margin-top: 10px;
}
.hint.bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0,0,0,0.25);
  top: -11px;
}
.hint.bottom .arrow:after {
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.step-list.body {
  padding: 0;
}
.step-list>.list {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 51px;
}
.step-list .sections .accordion-group {
  border-radius: 4px 10px;
  margin: 5px;
}
.step-list .sections .accordion-group .accordion-inner>.step {
  border-bottom: 1px solid #ccc;
}
.step-list .sections .accordion-group .accordion-inner>.step .goal {
  height: 34px;
  line-height: 34px;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.05), rgba(0,0,0,0.2));
  text-align: left;
  padding: 0 10px;
  color: #fff;
}
.step-list .sections .accordion-group .accordion-inner>.step .not-seen {
  border-left: 8px solid #00c236;
}
.step-list .sections .accordion-group .accordion-inner>.step .info {
  position: relative;
  cursor: pointer;
  background: linear-gradient(to bottom, #e6e5e5, #fff);
}
.step-list .sections .accordion-group .accordion-inner>.step .info .userpic {
  position: absolute;
  bottom: 5px;
  left: 6px;
  bottom: 3px;
  color: #ababab;
  font-size: 12px;
}
.step-list .sections .accordion-group .accordion-inner>.step .info .userpic img {
  margin-right: 5px;
  margin-top: -3px;
}
.step-list .sections .accordion-group .accordion-inner>.step .info a.link {
  display: block;
  position: absolute;
  top: 3px;
  height: 76px;
  right: 5px;
  width: 290px;
  border-radius: 4px 20px;
  background: linear-gradient(to Right, rgba(0,0,0,0), rgba(0,0,0,0.15));
}
.step-list .sections .accordion-group .accordion-inner>.step .info a.link::after {
  font-family: FontAwesome;
  content: '\e8c4';
  position: absolute;
  top: 25px;
  right: 10px;
  color: rgba(255,255,255,0.5);
  font-size: 35px;
}
.step-list .sections .accordion-group .accordion-inner>.step .info a.link:hover {
  background: linear-gradient(to Right, rgba(0,0,0,0.1), rgba(0,0,0,0.5));
}
.step-list .sections .accordion-group .accordion-inner>.step .info a.link:hover::after {
  font-size: 35px;
  animation: pulsate 1.5s infinite linear;
}
.step-list .sections .accordion-group .accordion-inner>.step .info .content {
  margin: 0 30px 0 30px;
  padding: 5px 18px 5px 5px;
}
.step-list .sections .accordion-group .accordion-inner>.step .info .content h1 {
  display: block;
  font-size: 15px;
  line-height: 16px;
  margin: 0;
  padding: 8px 0 0 0;
  font-weight: bold;
  min-height: 50px;
  font-family: triunity condensed;
  padding-bottom: 20px;
}
.step-list .sections .accordion-group .accordion-inner>.step.completed .info a.link {
  width: 260px;
}
.step-list .sections .accordion-group .accordion-inner>.step.completed .info h1 {
  text-decoration: line-through;
}
.step-list .sections .accordion-group .accordion-inner>.step.completed .info .circle {
  display: block;
  position: absolute;
  top: 10px;
  left: 8px;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
}
.step-list .sections .accordion-group .accordion-inner>.step.completed .info .circle::after {
  content: "\e833 ";
  color: #0ab8ca;
  font-family: FontAwesome;
  font-size: 16px;
  font-style: normal;
}
.step-list .sections .accordion-group .expander {
  clear: both;
  height: 20px;
  background: #efefef;
  border-bottom: 1px solid #dedede;
  padding: 2px 8px;
  cursor: pointer;
  border-top: 1px solid rgba(222,222,222,0.69);
}
.step-list .sections .accordion-group .expander:after {
  display: block;
  content: "\e8ca";
  text-align: center;
  font-family: FontAwesome;
  margin: 0 100px;
  color: #bebebe;
}
.step-list .sections .accordion-group .expander .due {
  font-size: 12px;
  color: #616161;
  float: right;
}
.step-list .sections .accordion-group .expander .icons {
  float: left;
  color: #686868;
}
.step-list .sections .accordion-group .expander .icons i {
  margin-right: 5px;
}
.step-list .sections .accordion-group .details {
  background: #e4e4e4;
}
.step-list .sections .accordion-group .details .title {
  font-family: triunity condensed bold;
  fborder-top: 1px solid #c8c8c8;
  padding: 0px 30px;
  color: #212121;
}
.step-list .sections .accordion-group .details .block {
  padding: 5px 20px 5px 30px;
}
.step-list .sections .accordion-group .details .desc {
  padding: 10px 10px 10px 30px;
}
.step-list .sections .accordion-group .expanded {
  border-bottom: 5px solid #636363;
}
.step-list .sections .accordion-group .expanded .expander {
  border-bottom: 1px solid #c2c2c2;
}
.step-list .sections .accordion-group .expanded .expander:after {
  content: "\e8c9";
}
.step-list .sections .accordion-group .accordion-heading {
  background: linear-gradient(to bottom, rgba(239,239,239,0.44), rgba(237,237,237,0.16));
  border-radius: 4px 10px;
  transition: 0.65s;
  perspective-origin: top;
  perspective: 600px;
}
.step-list .sections .accordion-group .accordion-heading .accordion-toggle {
  background: linear-gradient(to bottom, #333, #000 10%, #c8c8c8 80%, #646464);
  text-decoration: none;
  color: #fff;
  padding: 0;
  border-radius: 4px 10px;
  border-top: solid 1px #4c4c4c;
}
.step-list .sections .accordion-group .accordion-heading .accordion-toggle h5 {
  margin: 0;
  font-weight: normal;
  font-size: inherit;
}
.step-list .sections .accordion-group .accordion-heading .accordion-toggle .title {
  padding: 10px;
  transition: 0.35s;
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.3));
  box-shadow: inset -2px -3px 8px rgba(0,0,0,0.28);
  border-bottom: none;
  border-radius: 4px 10px;
}
.step-list .sections .accordion-group .accordion-inner {
  padding: 0;
  margin: 0 3px 0 9px;
  overflow: hidden;
  border-radius: 0 0 4px 10px;
}
.step-list .sections .accordion-group.expanded .accordion-heading .accordion-toggle .goal.title {
  box-shadow: none;
  transform: translate3d(0, 0, 0);
  transform: rotateX(30deg);
  background: linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.4) 30%, rgba(0,0,0,0.1));
  border-top: solid 1px #000;
}
.step-list > .head {
  background: linear-gradient(to bottom, rgba(72,72,72,0.45) 40%, rgba(0,0,0,0.54)) transparent;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: solid 1px #4c4c4c;
  color: #474646;
}
.step-list > .head .prev {
  display: block;
  float: left;
}
.step-list > .head .prev .arrow {
  display: block;
  width: 0;
  height: 0;
  margin: 13px 8px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #474646;
  box-shadow: -2px 2px 5px rgba(0,0,0,0.5);
}
.step-list > .head .next {
  display: block;
  float: right;
}
.step-list > .head .next .arrow {
  display: block;
  width: 0;
  height: 0;
  margin: 13px 8px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #474646;
  box-shadow: -2px 2px 5px rgba(0,0,0,0.5);
}
.step-list > .head .text {
  color: #474646;
  padding: 3px 15px 4px;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  position: relative;
  margin: 0 30px;
  text-shadow: -2px 2px 5px rgba(0,0,0,0.5);
}
.step-list > .head .text .month {
  position: absolute;
  left: 0;
  bottom: -10px;
  font-size: 10pt;
  line-height: 10px;
}
.there-is-more {
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  left: 0;
  width: 100%;
  background: #c8c8c8;
  background: rgba(200,200,200,0.8);
  color: #000;
  font-weight: bold;
  ont-family: triunity condensed, sans-serif;
  padding: 5px;
}
.with-inner-menu .there-is-more {
  bottom: 20px;
}
.difficulty-slider {
  position: relative;
}
.difficulty-slider .slider {
  margin: 0 50px;
}
.slider-label-left {
  position: absolute;
  left: 0;
  margin-top: -2px;
}
.slider-label-right {
  position: absolute;
  right: 0;
  margin-top: -2px;
}
slider {
  display: block;
  height: 7px;
  margin-top: 5px;
  margin-bottom: 5px;
}
slider.noUi-background {
  background-color: #5e5e5e;
}
slider.noUi-horizontal {
  height: 13px;
}
slider.noUi-horizontal .noUi-handle {
  border-radius: 50%;
  top: -4px;
  height: 18px;
  width: 18px;
  background: url("/src/images/slider.png") top left no-repeat;
  cursor: pointer;
}
slider.noUi-horizontal .noUi-handle:before {
  display: none;
}
slider.noUi-horizontal .noUi-handle:after {
  display: none;
}
slider.noUi-horizontal .tooltip {
  opacity: 0.5;
  filter: alpha(opacity=.5);
  top: 20px;
  width: 55px;
  left: -18px;
  text-align: center;
  font-weight: normal;
  background: #fff;
  padding: 2px 5px;
  border: solid 1px #7e7e7e;
  border-radius: 3px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
}
.select2-container .image-entry img,
.select2-results .image-entry img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid #c2c2c2;
  margin-right: 5px;
}
body.dragging,
body.dragging * {
  cursor: move !important;
}
.draggable {
  position: relative;
}
.draggable .placeholder {
  position: relative;
  border-top: 1px solid #292929;
  border-bottom: 1px solid #888;
}
.draggable .placeholder:before {
  position: absolute;
}
.dragged {
  opacity: 0.5;
  z-index: 2000;
}
.drag-clone {
  z-index: 2000;
}
.circle {
  width: 70px;
  position: relative;
  margin-bottom: 40px;
  text-align: center;
  cursor: pointer;
}
.circle .main {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
}
.circle.selected .main:after {
  content: '';
  width: 0px;
  height: 0;
  border-radius: 50%;
  position: absolute;
  left: 25px;
  top: 19px;
  background-color: inherit;
  border: 5px solid;
  border-color: inherit;
}
.circle .name {
  position: absolute;
  top: 100%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
}
.circle:before {
  content: '';
  background: #fff;
  width: 30px;
  height: 30px;
}
.pack {
  background-image: linear-gradient(to bottom, rgba(218,218,218,0.59), rgba(194,194,194,0.54), rgba(167,167,167,0.46));
  border-radius: 4px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 10px;
  color: #fff;
}
.pack > .header {
  padding: 10px 10px 5px 10px;
  color: #ededed;
  font-size: 16px;
  font-family: 'triunity condensed', sans-serif;
  background: linear-gradient(to bottom, #f2eded, #c9c5c5);
  border-radius: 4px 10px;
  line-height: 25px;
  z-index: 500;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.35);
  margin: -5px;
  position: relative;
}
.pack > .body {
  color: #333;
  padding: 10px;
}
.pack > .body .title {
  font-weight: bold;
  margin-bottom: 4px;
}
.pack .name {
  font-weight: normal;
  color: #454545;
  font-size: 16px;
}
.pack .details {
  float: left;
  color: #898989;
  margin-top: 3px;
  font-size: 12px;
  background: rgba(210,210,210,0) linear-gradient(to right, rgba(255,255,255,0.44), rgba(255,255,255,0.3) 70%, rgba(255,249,249,0));
  padding: 3px 40px 3px 10px;
  position: relative;
  border-radius: 0 4px 10px 0;
  margin-left: -8px;
}
.pack .details .field {
  display: inline-block;
  margin-right: 10px;
}
.pack .details .field > * {
  vertical-align: middle;
}
.pack .frame {
  padding: 0 0 10px;
}
.pack .frame > .title {
  height: 30px;
  line-height: 36px;
  font-weight: normal;
  color: #757575;
}
.pack .frame > .title .buttons {
  float: right;
  line-height: 26px;
}
.pack .frame > .title .frame-filter {
  float: right;
}
.pack .frame > .title .frame-filter select,
.pack .frame > .title .frame-filter input {
  width: 150px;
  margin-right: 10px;
}
.pack .frame > .body {
  padding: 5px;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.14), rgba(255,255,255,0.53));
  border-radius: 4px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #a5a5a5;
  border-top: rgba(0,0,0,0.42) solid 1px;
  border-bottom-color: #f4f4f4;
  box-shadow: inset 1px 1px 5px rgba(0,0,0,0.27);
  min-height: 25px;
}
.pack .frame > .body > .list-item {
  margin-left: 5px;
  margin-bottom: 5px;
  padding: 5px;
}
.pack .frame > .body.dragging-over-zone {
  border: 1px solid #aaa;
  box-shadow: 0 0 6px #000;
  background: #7cc54a;
}
.pack .frame > .body .loader {
  background: url("/src/images/loaders/horizontal.gif") no-repeat 50% 50%;
  width: 60px;
  height: 60px;
  margin: 5px 0 0 10px;
}
.pack .buttons {
  float: right;
}
.pack.dragging-over-zone {
  border: 1px solid #aaa;
  box-shadow: 0px 0px 6px #000;
  background: #bdd3a5;
}
.pack.expandable > .header {
  vertical-align: middle;
  padding: 11px 10px 0px 0px;
  background: linear-gradient(to bottom, #b8b8b8, #a5a5a5, #d2d2d2);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  padding-left: 50px;
  position: relative;
}
.pack.expandable > .header::before {
  box-sizing: border-box;
  font-family: FontAwesome;
  content: '\e8c4';
  color: #c2c2c2;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 20px;
  background: linear-gradient(to bottom, #9d9d9d, #1a1a1a);
  display: block;
  text-align: center;
  padding: 11px;
  vertical-align: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 42px;
}
.pack.expandable > .header .buttons {
  margin-top: -5px;
}
.pack.expandable > .body {
  clear: both;
  display: none;
}
.pack.expandable.expanded > .header {
  border-bottom-left-radius: 0px;
}
.pack.expandable.expanded > .header::before {
  content: '\e8c6';
}
.pack.expandable.expanded > .body {
  display: block;
  background: #d3d3d3 linear-gradient(to bottom, #d2d2d2, #c0c0c0 20%, #b0b0b0);
  border-bottom-left-radius: 20px;
  padding: 10px;
}
.left-column .pane .pack.dragging-over-zone {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 0px 6px #ececec;
}
filter + .pack {
  margin-top: 40px;
}
achievement {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}
.achievement {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 3px 5px;
  cursor: pointer;
  text-align: center;
}
.achievement .progress {
  margin-top: 6px;
  margin-bottom: 0;
  height: 10px;
}
.achievement img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid #fff;
  text-align: center;
  box-shadow: 2px 2px 5px #525252;
  border-color: #c2c2c2;
}
.newBadge {
  padding: 15px;
  background: radial-gradient(at 50% 10%, rgba(255,255,255,0.8), rgba(0,0,0,0)) #adadad;
  font-family: triunity condensed, sans-serif;
  margin-bottom: 3px;
  border-radius: 4px 10px;
  overflow: hidden;
}
.newBadge .badgeHolder {
  background: url("/src/images/laurelWreath.svg") no-repeat;
  background-size: 160px 160px;
  width: 100px;
  padding: 30px;
  margin: 0 auto;
}
.newBadge .challengeHolder {
  background: url("/src/images/challenge.svg") no-repeat;
  background-size: 160px 160px;
  width: 100px;
  height: 100px;
  padding: 30px;
  margin: 0 auto;
}
.newBadge .circle-50 {
  width: 90px;
  height: 90px;
  margin-top: -12px;
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}
.newBadge .title {
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  text-align: center;
  font-weight: bold;
}
.newBadge .description {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}
.newBadge .row-label {
  font-weight: bold;
}
achievementdetail {
  cursor: pointer;
}
achievementdetail .achievementDetail {
  font-family: triunity condensed, sans-serif;
  padding: 3px;
  margin-bottom: 3px;
  background: #bdbdbd;
  border-radius: 4px 10px;
  overflow: hidden;
  white-space: nowrap;
}
achievementdetail .achievementDetail:hover {
  background: #9c9c9c;
}
achievementdetail .achievementDetail:hover .title {
  color: #fff !important;
}
achievementdetail .achievementDetail .title {
  display: inline-block;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: pre;
  width: 80%;
  overflow: hidden;
  border-bottom: none !important;
}
achievementdetail .progress {
  margin-top: 6px;
  margin-bottom: 0;
  height: 10px;
}
achievementdetail img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid #fff;
  text-align: center;
  box-shadow: 2px 2px 5px #525252;
  border-color: #c2c2c2;
  margin-right: 10px;
  vertical-align: sub;
}
.date-picker {
  background-clip: padding-box;
  background-color1: #000;
  background: url("/src/images/content-bg-rev.gif") repeat;
  padding: 0;
  border-radius: 4px 10px;
}
.date-picker .disabled {
  opacity: 1;
}
.date-picker .header {
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-size: 0.9em;
  box-shadow: 0 1px 0 #7c7c7c, 0 2px 0 #444;
  padding: 4px 10px;
}
.date-picker .header a {
  color: #999;
}
.date-picker .header .title {
  text-align: center;
}
.date-picker table {
  font-size: 0.8em;
  margin: 0;
  background: rgba(150,150,150,0.18);
  box-shadow: 0 1px 0 #7c7c7c, 0 2px 0 #444;
}
.date-picker table td {
  padding: 5px 7px;
}
.date-picker table td a {
  padding: 4px 6px;
  text-align: center;
  border: 1px solid transparent;
  border-bottom: none;
  color: #999;
}
.date-picker:hover {
  cursor: pointer;
}
.date-picker .active {
  color: #fff;
  background-image: linear-gradient(to top, rgba(0,0,0,0.45) 0%, rgba(255,255,255,0.45) 100%);
  border-radius: 4px 10px;
}
.date-picker .muted {
  color: #595959;
}
.date-picker th {
  color: #cfc8bc;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: normal;
}
.date-picker .default {
  background: transparent;
  border: none;
  color: #ab9f8d;
  font-weight: normal;
}
.date-picker .disabled .default {
  color: #cfc7bb;
  text-align: center;
}
.modal.splash-screen {
  margin-left: 0 !important;
  z-index: 5000 !important;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100%;
  background: rgba(60,60,60,0.43);
  border-radius: 0;
}
.modal.splash-screen .wrapper {
  max-width: 300px;
  margin: auto;
  margin-top: 10%;
}
.modal.splash-screen .message {
  background: url("/src/images/content-bg.gif") repeat;
  border-radius: 15px;
}
.modal.splash-screen .body {
  background: radial-gradient(rgba(195,252,84,0.53) 0%, rgba(130,230,179,0.5) 60%, rgba(122,210,42,0.98) 100%);
  background-size: 400% 400%;
  animation: flow;
  animation-direction: alternate;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  color: #064002;
  padding: 20px;
  border-radius: 15px;
}
.modal.splash-screen .body h1,
.modal.splash-screen .body h2 {
  margin-top: 25px;
  border: none;
}
.mce-statusbar {
  display: block;
}
.mce-statusbar .mce-container-body {
  height: 15px;
}
.mce-statusbar .mce-path {
  display: none;
}
section.ng-video-container {
  width: 550px;
  height: 310px;
  margin: 40px auto auto auto;
}
section.video {
  text-align: left;
  position: relative;
  width: 100%;
  height: 100%;
}
section video {
  background-color: rgba(0,0,0,0.25);
  width: 100%;
  height: 100%;
  object-fit: fill;
}
section .loading {
  position: absolute;
  top: 39px;
  left: 5px;
  width: 538px;
  height: 260px;
  color: #fff;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.25);
  font-size: 12px;
  pointer-events: all;
  z-index: 201;
  padding: 13px 0 0 40px;
  background-color: rgba(0,0,0,0.25);
}
section .controls {
  position: absolute;
  top: 15px;
  right: 25px;
  width: 48px;
  z-index: 5;
  text-align: center;
  color: #fff;
}
section .controls span {
  margin: 0;
  padding: 0;
  cursor: pointer;
  line-height: 48px;
  padding-left: 2px;
  width: 48px;
  height: 48px;
  font-size: 20px;
  transition: background-color 0.35s;
  border-radius: 3px;
  margin-bottom: 10px;
}
section .controls span:hover {
  background-color: rgba(255,255,255,0.25);
}
section .volume {
  position: absolute;
  top: 145px;
  right: 25px;
  width: 48px;
}
section .volume .scale {
  background-color: rgba(0,0,0,0.15);
  width: 48px;
  height: 5px;
  position: relative;
}
section .volume .scale .bar {
  background-color: rgba(255,255,255,0.75);
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 50%;
  transition: width 0.25s;
}
section .volume .scale span {
  color: rgba(0,0,0,0.25);
  font-size: 15px;
  pointer-events: all;
  cursor: pointer;
  margin-top: 10px;
}
section .volume .scale span.increase {
  float: right;
}
section .feedback {
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  z-index: 4;
  width: 100%;
  height: 100%;
}
section .feedback .buffering {
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 18px 0 0 45px;
}
section .generic {
  position: absolute;
  bottom: 11px;
  right: 10px;
  z-index: 90;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  color: rgba(255,255,255,0.5);
}
section .generic a {
  text-transform: none;
  color: rgba(255,255,255,0.75);
  pointer-events: all;
}
section .generic a:hover {
  text-decoration: none;
}
section .timeline {
  position: absolute;
  width: 485px;
  width: calc(100% - 60px);
  left: 30px;
  bottom: 32px;
}
section .timeline input {
  width: 100%;
  pointer-events: all;
  appearance: none;
  background-color: transparent;
  outline: none;
  height: 12px;
  border-bottom: 3px solid rgba(0,0,0,0.25);
}
section .timeline input input::-webkit-slider-thumb {
  appearance: none;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 2px;
  outline: none;
  height: 15px;
  width: 45px;
}
section .timeline input input::-moz-range-thumb {
  appearance: none;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 2px;
  outline: none;
  height: 15px;
  width: 45px;
}
section .duration {
  background-color: rgba(0,0,0,0.15);
  color: rgba(255,255,255,0.5);
  padding: 2px 5px;
  border-radius: 3px 0 0 3px;
  position: absolute;
  font-size: 11px;
  bottom: 62px;
  right: 83px;
}
section .title {
  background-color: #dd4b39;
  font-size: 11px;
  position: absolute;
  color: #fff;
  opacity: 0.8;
  padding: 2px 5px;
  border-radius: 0 3px 3px 0;
  bottom: 62px;
  right: 33px;
}
section .playlist {
  height: 297px;
  position: absolute;
  width: 200px;
  top: 5px;
  left: 5px;
  background-color: rgba(0,0,0,0.35);
}
section .playlist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
section .playlist ul li {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  position: relative;
  padding: 0 12px;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.25);
  color: #fff;
  font-size: 12px;
}
section .playlist ul li .meta {
  margin-right: 8px;
  padding: 1px 5px;
  border-radius: 2px;
  background-color: rgba(0,0,0,0.25);
}
section .playlist ul li.title {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.25);
}
section .playlist ul li.title .close-playlist {
  position: absolute;
  top: -1px;
  font-size: 20px;
  right: 0;
  cursor: pointer;
  width: 35px;
  text-align: center;
  color: #fff;
}
section .playlist ul li:not(.title):hover {
  background: rgba(0,0,0,0.25);
}
section .open-playlist {
  color: #fff;
  position: absolute;
  cursor: pointer;
  top: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
}
section .buffer {
  position: absolute;
  width: 485px;
  width: calc(100% - 60px);
  left: 30px;
  bottom: 30px;
  background-color: rgba(255,255,255,0.5);
  height: 1px;
}
section .buffer canvas {
  margin: 0;
  position: absolute;
  width: 100%;
  top: 0;
}
section .full-screen {
  position: absolute;
  top: 40px;
  left: 6px;
  cursor: pointer;
  padding: 10px;
  color: #fff;
}
.assessment table.list thead td {
  padding: 0 10px;
  white-space: nowrap;
}
.print .assessment h1 {
  font-size: 1.3em;
}
.print .assessment h2 {
  font-size: 1em;
  line-height: 1.1em;
}
.print .assessment h3 {
  font-size: 1em;
  line-height: 1.1em;
}
.print .assessment table.list td {
  height: auto;
  padding: 0 5px;
}
.print .assessment table.list.min-width {
  font-size: 0.85em;
  padding: 0;
}
.print .assessment table.list thead td {
  font-size: 0.85em;
  padding: 0 10px;
  white-space: nowrap;
}
.print .assessment table.list thead td.min-width {
  font-size: 8px;
  padding: 0 4px;
}
.print .assessment .category {
  margin-bottom: 30px;
}
.print .assessment .category .comment {
  border: 1px solid #d8d8d8;
  padding: 0 20px;
  margin-top: 10px;
}
.quiz-group {
  border: 1px solid #9e9e9e;
  margin-bottom: 15px;
  border-radius: 4px 10px;
}
.quiz-group .head {
  background: #4f4f4f linear-gradient(to right, rgba(255,255,255,0.34), rgba(255,255,255,0.28) 50%, rgba(255,249,249,0));
  color: #e7e7e7;
  border-radius: 4px 10px 0 0;
}
.quiz-group .head h4 {
  padding: 2px 10px;
}
.quiz-group .richtext {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin-bottom: 0;
}
.quiz-group .richtext .display {
  margin: 10px;
  min-height: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.quiz-group .placeholder [class*="icon"] {
  font-size: 20px;
  line-height: 1.1em;
  margin-right: 5px;
  vertical-align: middle;
}
.quiz-group > .question {
  background: linear-gradient(to top, #e0e0e0, #cecece);
  border: 1px solid #e5e5e5;
  border-bottom: 1px solid #a0a0a0;
  padding: 6px;
  box-sizing: border-box;
  min-height: 22px;
}
.quiz-group > .question > .draggable {
  float: left;
  margin-top: 10px;
  font-size: 20px;
  color: #808080;
  cursor: pointer;
}
.quiz-group > .question > .draggable.active {
  color: #008000;
}
.quiz-group > .question > .text {
  margin-right: 40px;
}
.quiz-group > .question > .text .placeholder {
  margin-top: 6px;
}
.quiz-group > .question .btn-group {
  float: right;
}
.quiz-group .add-more {
  background: linear-gradient(to bottom, #e7e7e7, #f1f1f1 26%, #fff);
  padding: 8px;
  box-sizing: border-box;
  border-top: 1px solid #9e9e9e;
  min-height: 22px;
  padding-left: 16px;
}
.quiz-group .add-more .text {
  cursor: pointer;
}
.quiz-group .answer,
.angular-ui-tree-drag .answer {
  background: linear-gradient(to bottom, #e7e7e7, #f1f1f1 26%, #fff);
  padding: 6px;
  box-sizing: border-box;
  border-top: 1px solid #9e9e9e;
  min-height: 22px;
}
.quiz-group .answer > .text,
.angular-ui-tree-drag .answer > .text {
  margin-left: 38px;
  margin-right: 40px;
}
.quiz-group .answer .checkbox,
.angular-ui-tree-drag .answer .checkbox {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}
.quiz-group .answer .btn-group,
.angular-ui-tree-drag .answer .btn-group {
  float: right;
}
.quiz-group .answer .richtext,
.angular-ui-tree-drag .answer .richtext {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin-bottom: 0;
}
.quiz-group .answer .richtext .display,
.angular-ui-tree-drag .answer .richtext .display {
  margin: 10px;
  min-height: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.quiz-group .answer .move-handle,
.angular-ui-tree-drag .answer .move-handle {
  border-radius: 0 !important;
  padding: 7px 7px 2px;
  margin-top: 4px;
  background: #7d90db;
  color: #fff;
}
.modal .modal-body.quiz-view {
  background: #fff;
}
.quiz-view {
  padding: 0 0 10px 0;
  text-align: left;
}
.quiz-view .title {
  padding: 0 10px 3px 10px;
  margin: 0 -10px 10px -10px;
  font-family: triunity condensed bold;
  font-size: 33px;
  line-height: 30px;
  padding: 0 10px 3px 10px;
  margin: 0 -10px 10px -10px;
  color: #707070;
}
.quiz-view .meta {
  font-size: 0.9em;
  margin-bottom: 5px;
}
.quiz-view .question {
  padding-bottom: 10px;
}
.quiz-view .question .heading {
  font-weight: normal;
  background: linear-gradient(to bottom, #192418, #5c5c5c);
  padding: 10px 10px;
  border: 1px solid #afafaf;
  margin: 0 0 15px 0;
  border-radius: 4px 10px;
  color: #fff;
  position: relative;
}
.quiz-view .question .heading ::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #5a5a5a;
  bottom: -10px;
  position: absolute;
  left: 40px;
}
.quiz-view .question .heading ::last-of-type {
  padding-bottom: 60px;
}
.quiz-view .open-answer {
  margin: 10px 20px;
}
.quiz-view .answers-list {
  float: left;
}
.quiz-view .correct-list {
  float: right;
}
.quiz-view .answers-list,
.quiz-view .correct-list {
  position: relative;
  width: 48%;
  margin: 10px 0 0 0 !important;
  min-height: 100px;
  text-align: center;
  color: #ccc;
  position: relative;
}
.quiz-view .answers-list .answer,
.quiz-view .correct-list .answer {
  color: #000;
  text-align: left;
}
.quiz-view .answers-list .angular-ui-tree-empty,
.quiz-view .correct-list .angular-ui-tree-empty {
  display: block;
  min-height: 500px;
  background: rgba(0,0,0,0.02);
  border-radius: 4px 10px;
  border: dashed 2px #70d2d7;
}
.quiz-view .correct-list .answer::before {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: "\e89e";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  padding: 5px 15px;
  background-color: #8dc73f !important;
  color: #fff;
  border-radius: 4px 0 0 10px;
  padding-top: 16px;
  border-right: solid 1px #b8b8b8;
}
.quiz-view .correct-list .answer .text::before {
  display: none;
}
.quiz-view .match-answers .answer {
  background: rgba(0,0,0,0.05);
  border-radius: 4px 10px;
  padding: 6px;
  margin: 10px 0 0 0;
}
.quiz-view .match-answers .answer.answered {
  border: 1px solid #008000;
  background: rgba(0,0,0,0.16);
}
.quiz-view .match-answers .answer.answered ::before {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: "\e89e";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  padding: 5px 15px;
  background-color: #8dc73f;
  color: #fff;
  border-radius: 4px 0 0 10px;
  padding-top: 16px;
  border-right: solid 1px #b8b8b8;
}
.quiz-view .match-answers .answer .text {
  padding: 5px;
}
.quiz-view .match-answers > .texts {
  float: left;
  width: 48%;
}
.quiz-view .match-answers > .texts ::before {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: "\e89e";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  padding: 5px 15px;
  background-color: #fbb03f;
  color: #fff;
  border-radius: 4px 0 0 10px;
  padding-top: 16px;
  border-right: solid 1px #b8b8b8;
}
.quiz-view .match-answers > .texts h5 {
  color: #ccc;
  text-align: center;
}
.quiz-view .match-answers > .texts h5::before {
  display: none;
}
.quiz-view .match-answers > .texts .placeholder {
  display: none;
}
.quiz-view .match-answers > .texts.empty .placeholder {
  display: block;
  min-height: 100px;
  background: rgba(0,0,0,0.02);
  border-radius: 4px 10px;
  margin-top: 10px;
  border: dashed 1px #ccc;
  padding: 5px;
  text-align: center;
  color: #ccc;
  position: relative;
}
.quiz-view .match-answers > .texts.empty .placeholder::before {
  display: none;
}
.quiz-view .match-answers > .texts.empty .placeholder h2::before {
  display: none;
}
.quiz-view .match-answers > .matches {
  float: right;
  width: 49%;
}
.quiz-view .match-answers > .matches .answer {
  min-height: 15px;
  box-shadow: inset 2px 2px 5px #a9a9a9;
}
.quiz-view .match-answers.solving {
  padding-bottom: 60px;
}
.quiz-view .match-answers.solving .texts .answer {
  cursor: move;
}
.quiz-view .match-answers.solving .texts > .answer:hover,
.quiz-view .match-answers.solving .dragging-over-zone {
  background: rgba(0,0,0,0.16);
}
.quiz-view .match-answers.solving .matches .dragging-over-zone {
  background: #7cc54a;
}
.quiz-view .answer {
  margin: 10px 10px;
  box-shadow: 1px 1px 4px #b8b8b8;
  position: relative;
  border-radius: 4px 10px;
}
.quiz-view .answer textarea {
  width: 100%;
  height: 100px;
}
.quiz-view .answer.correct {
  background: #24be24;
  box-shadow: 0px 0px 3px #575757;
  padding: 4px 0;
  color: #fff;
}
.quiz-view .answer.incorrect {
  background: #f7c90d;
  box-shadow: 0px 0px 3px #575757;
  padding: 4px 0;
  color: #fff;
}
.quiz-view .answer .checkbox {
  font-size: 1.5em;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 1.6em;
  position: absolute;
  border-radius: 4px 0px 0px 10px;
}
.quiz-view .answer .checkbox i {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  left: 0.5em;
  color: #fff;
  width: 15px;
}
.quiz-view .answer .checkbox a.checkbox {
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 1.2em;
  background: #fbaf3f;
  position: absolute;
  border-radius: 4px 0px 0px 10px;
  padding-left: 10px;
  border-right: solid 1px #b8b8b8;
}
.quiz-view .answer .checkbox a.checkbox.checked {
  background: #8dc73f !important;
}
.quiz-view .answer .checkbox a.checkbox i {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  left: 0.5em;
  color: #fff;
  width: 15px;
}
.quiz-view .answer .checkbox [class^="icon-"] {
  vertical-align: middle;
}
.quiz-view .answer .text {
  margin-left: 60px;
  padding: 10px;
}
.quiz-view .answer.no-checkbox .checkbox,
.quiz-view .answer > .no-checkbox .checkbox {
  display: none;
}
.quiz-view .answer.no-checkbox .text,
.quiz-view .answer > .no-checkbox .text {
  margin-left: 35px;
}
.quiz-view .answer.angular-ui-tree-node {
  background: rgba(0,0,0,0.05);
  border-radius: 4px 10px;
  margin: 2px 0;
  font-weight: normal;
  width: 100%;
}
.quiz-view .answer.angular-ui-tree-node .text {
  margin: 0px 15px 0 45px;
}
.quiz-view .answer.angular-ui-tree-node:hover {
  background: rgba(0,0,0,0.16);
}
.quiz-view .answer.angular-ui-tree-handle ::before {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: "\e89e";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  padding: 5px 15px;
  background-color: #fbb03f;
  color: #fff;
  border-radius: 4px 0 0 10px;
  padding-top: 12px;
  border-right: solid 1px #b8b8b8;
}
.quiz-view .angular-ui-tree-nodes {
  margin: 0 15px;
}
.quiz-view > .buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  height: 20px;
  padding: 5px 15px;
  background: #ccc;
}
.quiz-view > .buttons .button,
.quiz-view > .buttons button,
.quiz-view > .buttons .btn {
  margin-right: 10px;
}
.quiz-view > .buttons .button.active,
.quiz-view > .buttons button.active,
.quiz-view > .buttons .btn.active {
  color: #fff;
  background: linear-gradient(to bottom, #777, #9e9e9e, #585858);
}
.blurry-text {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.answer.drag-clone {
  width: 300px;
  background: rgba(0,0,0,0.16);
  border-radius: 4px 10px;
  padding: 10px;
  margin: 10px 0 0 0;
}
.angular-ui-tree-placeholder {
  background: #7cc54a !important;
  border-radius: 4px 10px;
  border: dashed 1px #ccc !important;
}
.angular-ui-tree-drag.quiz_answer {
  border: none !important;
  border-radius: 4px 10px;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}
.angular-ui-tree-drag.quiz_answer .text {
  margin: 0px 15px 0 55px;
  padding: 4px;
  padding-left: 15px;
}
.angular-ui-tree-drag.quiz_answer ::before {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  content: "\e89e";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  padding: 5px 15px;
  background-color: #8dc73f;
  color: #fff;
  border-radius: 4px 0 0 10px;
  padding-top: 8px;
}
.print .quiz .question {
  margin-top: 10px;
}
.print .quiz .question .question-number {
  display: inline-block;
  font-weight: bold;
  float: left;
  margin-right: 10px;
}
.print .quiz .question .open-answer {
  min-height: 200px;
}
.print .quiz .question .answers {
  margin-top: 10px;
}
.print .quiz .question .answers .answer .checkbox {
  float: left;
  line-height: 42px;
  font-size: 26px;
  line-height: 42px;
}
.print .quiz .question .answers .answer .text {
  margin-left: 46px;
  padding: 10px 12px 12px;
}
.print .quiz .question .answers .answer .letter {
  float: left;
  line-height: 40px;
  padding-left: 10px;
}
.print .quiz .question .answers .answer:after {
  content: '';
  display: block;
  clear: both;
}
.print .quiz .question .answers .answer.order .letter {
  width: 80px;
  white-space: nowrap;
}
.print .quiz .question .answers .answer.order .letter:before {
  content: '';
  display: inline-block;
  border-bottom: 1px solid #808080;
  float: left;
  width: 40px;
  height: 26px;
  margin-right: 10px;
  margin-left: 20px;
}
.print .quiz .question .answers .answer.order .text {
  width: 100%;
}
.print .quiz .question .answers .answer.match .matched .letter {
  width: 80px;
  white-space: nowrap;
}
.print .quiz .question .answers .answer.match .matched .letter:before {
  content: '';
  display: inline-block;
  border-bottom: 1px solid #808080;
  float: left;
  width: 40px;
  height: 26px;
  margin-right: 10px;
  margin-left: 20px;
}
.print .quiz .question .answers .answer.match .matched .text {
  width: 100%;
}
.print .quiz .question .answers .answer.match .options .letter {
  padding-left: 40px;
  width: 10px;
}
.print .quiz .question .answers .answer.match .options .text {
  width: 100%;
}
.print .quiz .question .answers.no-checkbox .answer .text {
  margin-left: 20px;
}
.print .quiz .question .break {
  margin-top: 10px;
}
.print .quiz .span6 {
  float: left;
  width: 50%;
}
.print .quiz .underline {
  border-bottom: 1px solid #d0d0d0;
}
.print .quiz.solution .question .answers .answer.order .letter {
  width: 15px;
  margin-left: 20px;
}
.print .quiz.solution .question .answers .answer.order .letter:before {
  display: none;
}
.survey .question.active {
  display: block;
  padding-bottom: 60px;
}
.survey .question.inactive {
  display: none;
}
.survey .question .surveyQuestion {
  position: relative;
  height: 300px;
}
.survey .heading h3 {
  font-size: 40px;
  line-height: 45px;
  font-weight: normal;
  font-style: normal;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.survey .head .select2-container {
  margin-top: 10px;
}
.survey .btn {
  padding: 10px 15px;
  font-size: 15px;
}
.survey.quiz-view .angular-ui-tree-empty {
  min-height: 70px;
}
.survey .placeholder {
  display: none;
}
.survey .match-answers h5 {
  display: none;
}
.wrap {
  border: 1px solid #9e9e9e;
  border-bottom: none;
  border-radius: 4px 20px;
  margin-top: 3px;
  overflow: hidden;
}
.lesson-explore .category {
  padding: 3px 0;
  right: -13px;
  position: relative;
  margin: 0;
}
.lesson-explore .category .title {
  width: 80%;
  background: #bdbdbd;
  padding: 8px 5px;
  padding-left: 28px !important;
  margin: 5px 0px 10px 15px;
  color: #fff;
  font-size: 1.2em;
  border-bottom-left-radius: 5px;
}
.lesson-explore .category .circle-30 {
  width: 33px;
  height: 33px;
  border: none;
  float: left;
  color: #fff;
}
.lesson-explore .category .circle-30 .icon {
  line-height: 33px;
}
.lesson-list .category-title {
  background: #bfbfbf;
  color: #f2f2f2;
  padding: 3px 10px 3px 15px;
  border-bottom-right-radius: 5px;
  width: 200px;
  margin: 10px 0;
}
.lesson-list .circle-30 {
  float: right;
  margin-right: 20px;
  border: none;
  color: #fff;
  margin-top: -3px;
}
.lesson {
  margin: 10px 20px 10px 20px;
  padding: 5px;
  border-bottom: 1px #ccc solid;
  color: #989898;
  cursor: pointer;
}
.lesson:last-of-type {
  border-bottom: none;
  margin-bottom: 20px;
}
.lesson:hover {
  background: #a2a2a2;
  color: #fff;
  margin-right: 0;
  padding-right: 25px;
  border-bottom-left-radius: 5px;
}
.lesson-view h4 {
  border-bottom: solid 1px #ccc;
  padding: 4px;
  font-size: 1.1em;
  cursor: pointer;
}
.lesson-view .instructions {
  margin: 40px auto;
  padding: 30px;
  max-width: 800px;
}
.lesson-view .instructions li {
  margin-bottom: 15px;
}
.lesson-view .instructions .banner {
  border-radius: 4px 20px;
  margin: 0 5px 30px;
  overflow: hidden;
  background-image: url("/src/images/clip0007.jpg");
  background-size: cover;
  background-position: top center;
  color: #fff;
  height: 250px;
}
.lesson-view .instructions .banner h1 {
  font-weight: normal;
  margin: 30px;
}
.lesson-view .instructions .tip {
  font-style: italic;
  color: #9b9b9b;
  font-size: 14px;
}
.lesson-view .container .btn-group.buttons {
  padding: 10px 0px;
}
.lesson-view .modal-header .category,
.lesson-view .modal-header .version {
  font-style: italic;
  font-size: 0.5em;
  padding-top: 3px;
  margin-right: 4px;
  border: 1px solid #ccc;
}
.lesson-view .description .title {
  font-weight: bold;
}
.lesson-view .frame {
  visibility: hidden;
  transition: 0.25s;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.lesson-view .steps {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 3px;
}
.lesson-view .steps.odd {
  background-color: #f0f0f0;
}
.lesson-view .steps.inactive {
  color: #c1bbbb;
}
.lesson-view .steps.inactive .pill {
  background-color: #ccc !important;
}
.lesson-view .opened .frame {
  visibility: visible;
  margin-bottom: 15px;
  opacity: 1;
  height: auto;
}
.lesson-view .opened .steps {
  padding: 10px;
  background-color: #f6f6f6;
  border-bottom: 1px solid #fff;
}
.lesson-view .opened .steps.odd {
  background-color: #f0f0f0;
}
.lesson-view .opened .steps .name {
  padding: 10px;
}
.lesson-view .fred {
  border-bottom: 1px solid #dadada;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.lesson-view .heading {
  background: #ccc;
  padding: 5px;
  color: #fff;
  margin-bottom: 10px;
}
.lesson-view h3 {
  font-size: 22px;
  font-weight: normal;
  color: #555;
  line-height: 22px;
  padding: 0 10px 3px 10px;
  margin: 5px 0px 25px 0;
  border-bottom: 1px solid #a8a8a8;
}
.lesson-view .section h4 {
  border-bottom: solid 1px #ccc;
  padding: 4px;
  font-size: 1.1em;
  cursor: pointer;
}
.lesson-view .plan {
  margin-bottom: 20px;
}
.lesson-view .plan > .title .duration {
  font-weight: normal;
  font-style: italic;
}
.lesson-view .word.list {
  margin-bottom: 20px !important;
}
.lesson-view .word.list .target {
  padding-left: 5px;
}
.lesson-view .word.list .meaning {
  padding: 5px 15px;
  border-bottom: 1px dotted #9e9e9e;
  color: #9e9e9e;
  font-style: italic;
}
.lesson-view .action-steps .accordion-inner {
  padding: 0;
}
.lesson-search .head {
  position: absolute;
  top: 0px;
  height: 37px;
  left: 0px;
  width: 250px;
  background: #fff;
  border-right: solid 1px #ccc;
}
.lesson-search .head .text {
  color: #989898;
  padding: 5px 15px 4px;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  position: relative;
  margin: 0 auto;
}
.lesson-search .modal-body {
  background: linear-gradient(to right, #e5e5e5 250px, #f2f2f2 300px);
}
.lesson-search .lister {
  position: absolute;
  top: 37px;
  bottom: 0px;
  left: 0px;
  width: 250px;
  padding-left: 0px;
  background: #fff;
  border-right: solid 1px #ccc;
}
.lesson-search .circle {
  float: left;
  margin-right: 10px;
  background: #bfbfbf;
  padding: 0px 20px 3px 10px;
  width: 180px;
  border-radius: 0;
  margin: 5px 0px;
}
.lesson-search .circle .name {
  position: relative;
  color: #f2f2f2;
  text-align: left;
}
.lesson-search .circle .main {
  border: solid 5px;
  background: #fff;
  width: 20px;
  height: 20px;
  float: right;
  margin: 0px;
  position: absolute;
  right: -10px;
  top: -5px;
}
.lesson-search .circle .main:after {
  top: 5px;
  left: 5px;
}
.lesson-search .results {
  border-top: 1px solid #999;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  background: linear-gradient(to right, #e5e5e5 0%, #f2f2f2 7%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 61px;
}
.lesson-editor {
  background: #b3b3b3;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.lesson-editor .modal-body {
  position: relative;
}
.lesson-editor .modal-body .container {
  max-width: 1000px;
  margin-top: -10px;
  height: 100%;
  margin: auto;
  overflow: auto;
}
.lesson-editor .tabNav {
  background: #ccc;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.lesson-editor .tabs {
  text-align: center;
  float: left;
  font-size: 16px;
  background: #ccc;
  color: #949494;
  cursor: pointer;
  border-bottom: 1px solid #b5b4b4;
  margin-left: 0 !important;
  width: 33% !important;
}
.lesson-editor .tabs.active {
  background: #fff;
  color: #000;
  border-radius: 4px 10px 0 0;
  border-bottom: 1px solid #fff;
}
.lesson-editor .tabs .name {
  padding: 10px;
}
.lesson-editor .botNav {
  position: sticky;
  bottom: 16px;
  left: 0;
  right: 0;
  padding: 10px;
}
.lesson-editor .container {
  background: #fff;
  width: auto;
  padding: 15px;
}
.lesson-editor .editor {
  position: absolute;
  top: 0;
  bottom: 70px;
}
.lesson-editor input,
.lesson-editor textarea,
.lesson-editor richtext,
.lesson-editor .richtext {
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  border-bottom: 1px solid #dadada;
}
.lesson-editor .richtext {
  border: none;
  margin-bottom: 0px;
}
.lesson-editor .richtext .display {
  min-height: 10px;
}
.lesson-editor .info {
  margin: 0px 10px 10px;
  font-size: 0.75em;
  color: #36768e;
}
.lesson-editor .headline {
  font-size: 22px;
  line-height: 22px;
  padding: 0 10px 3px 10px;
  margin: 0 -10px 10px -10px;
  border-bottom: 1px solid #a8a8a8;
}
.lesson-editor .editor,
.lesson-editor .mce-tinymce {
  max-width: 1000px;
  margin: auto;
  box-shadow: 0 0 10px #1b1b1b;
}
.lesson-editor.modal-body {
  position: relative;
}
.modal-header h3 {
  border-bottom: none;
}
.assign-lesson h4 {
  font-size: 22px;
  font-weight: normal;
  color: #555;
  line-height: 16px;
  padding: 0 10px 3px 10px;
  margin: 5px 0px 25px 0;
}
.assign-lesson h5 {
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  background: #a2a2a2;
}
.assign-lesson .body {
  height: 150px;
  background: #fff;
  width: 100%;
  border-radius: 0 4px 10px;
  box-shadow: inset 3px 3px 10px #ccc;
  overflow: hidden;
  padding-top: 5px;
}
.assign-lesson .body .list-item {
  background: #ccc;
  padding: 5px;
  margin: 2px 5px;
  border-radius: 4px 10px;
  cursor: pointer;
  color: #fff;
}
.assign-lesson .body .list-item:hover {
  background: #b5b5b5;
}
.assign-lesson .body .list-item .circle-30 {
  border: none;
  background: #008080;
  float: left;
}
.assign-lesson .body .list-item .name {
  float: left;
  margin: 7px;
}
.goal,
.wcircle {
  position: relative;
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  position: relative;
  display: inline-block;
  margin: 10px 15px;
  cursor: pointer;
  overflow: hidden;
  background: radial-gradient(#fff 100%, #eee 60%, #fff);
  border-radius: 50%;
  vertical-align: top;
}
.goal.hide_complete.complete,
.wcircle.hide_complete.complete {
  display: none;
}
.goal .autoassign,
.wcircle .autoassign {
  position: absolute;
  top: 18px;
  left: 34px;
  background: rgba(189,189,189,0.58);
  padding: 2px 3px 3px 3px;
  height: 10px;
  width: 8px;
  border-radius: 50%;
  font-size: 0.85em;
  line-height: 0.98em;
  color: #565656;
}
.goal .inactive,
.wcircle .inactive {
  background: radial-gradient(#a6a6a6 80%, #000);
}
.goal .icon,
.wcircle .icon {
  color: #353535 !important;
}
.goal svg,
.wcircle svg {
  height: 120px;
  width: 120px;
}
.goal .icon,
.wcircle .icon,
.goal .text,
.wcircle .text {
  position: absolute;
  top: 60px;
  left: 50%;
  margin-top: -30px;
  margin-left: -32px;
  width: 60px;
  height: 60px;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  color: #bdbdbd;
}
.goal .sticky,
.wcircle .sticky {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  color: #474747;
  text-align: center;
}
.goal .text,
.wcircle .text {
  font-size: 30px;
}
.goal .name,
.wcircle .name {
  margin-top: 0px;
  text-align: center;
  font-weight: bold;
  color: #404640;
  margin-left: 15px;
}
.goal .fill,
.wcircle .fill {
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  margin-top: -35%;
  margin-left: -35%;
  display: none;
}
.goal.small,
.wcircle.small {
  width: 50px;
  height: 50px;
  margin: 3px 5px;
}
.goal.small svg,
.wcircle.small svg {
  height: 50px;
  width: 50px;
}
.goal.small .icon,
.wcircle.small .icon {
  top: 25px;
  width: 26px;
  height: 26px;
  margin-top: -13px;
  margin-left: -13px;
  font-size: 21px;
  line-height: 26px;
}
.goal.small .sticky,
.wcircle.small .sticky {
  font-size: 0.9em;
  top: -4px;
  text-align: right;
}
.goal.small .name,
.wcircle.small .name {
  font-size: 0.9em;
  line-height: 0.92em;
}
.goal-view .user-info {
  float: right;
  font-size: 0.9em;
  margin-top: -6px;
}
.goal-view .user-info .icon {
  display: inline-block;
  font-size: 1.5em;
  margin-left: 4px;
  vertical-align: middle;
  margin-top: -2px;
}
.goal-view .user-info .icon .icon-check-empty {
  color: #c91717;
}
.goal-view .user-info .icon .icon-check {
  color: #008000;
}
.goal-view .description .icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 12px solid #fff;
  position: relative;
  float: left;
  margin-right: 20px;
}
.goal-view .description .icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}
.goal-view .description .title {
  font-size: 1.2em;
  font-weight: bold;
}
.goal-view .steps {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 3px;
}
.goal-view .steps.odd {
  background-color: #f0f0f0;
}
.goal-view .steps.inactive {
  color: #c1bbbb;
}
.goal-view .steps.inactive .pill {
  background-color: #ccc;
}
.goal-view .action-steps .name {
  width: 45%;
  display: inline-block;
}
.goal-view .action-steps .due {
  width: 15%;
  display: inline-block;
}
.goal-view .action-steps .complete {
  width: 15%;
  display: inline-block;
}
.goal-view .action-steps .rating {
  width: 12%;
  display: inline-block;
}
.goal-view .action-steps .eol {
  width: 12%;
  display: inline-block;
}
.goal-view .action-steps .accordion-inner .title {
  padding: 3px;
  border-bottom: 1px solid #cecece;
  font-weight: bold;
}
.goal-view .action-steps .accordion-inner .title.no-border {
  border: none;
}
.goal-view .action-steps .accordion-inner .body {
  padding: 3px;
}
.goal-data {
  border-radius: 4px 10px;
  margin-bottom: 10px;
  clear: both;
}
.goal-data > .title {
  background: linear-gradient(to bottom, #333, #000 10%, #c8c8c8 80%, #646464);
  border-radius: 4px 10px;
  perspective-origin: top;
  perspective: 1200px;
  overflow: hidden;
}
.goal-data > .title .owner {
  float: right;
  relative: top left;
  z-index: 11;
  margin-right: 5px;
  margin-left: 3px;
}
.goal-data > .title .owner img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 15;
}
.goal-data > .title .pivot {
  transition: 0.65s;
  background: linear-gradient(to bottom, rgba(155,155,155,0.5), rgba(175,175,175,0) 80%);
  background-color: #f2eded;
  display: block;
  relative: top left;
  text-align: left;
  cursor: pointer;
  padding: 8px 10px 6px;
  border-radius: 4px 10px;
  min-height: 20px;
}
.goal-data > .title .pivot .progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  height: 100%;
  z-index: 9;
}
.goal-data > .title .pivot .progress .bar {
  border-radius: 4px 10px;
  background: linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.3));
  border-right: solid 1px #fff;
  text-align: left;
  padding-top: 8px;
  padding-left: 10px;
  overflow: hidden;
}
.goal-data > .title .pivot .progress .bar .name {
  Color: #e6e6e6;
  text-overflow: clip;
  overflow: hidden;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.52);
}
.goal-data > .title .pivot .progress .bar .name .icon[style] {
  background-color: rgba(230,230,230,0.31);
  color: #e6e6e6 !important;
}
.goal-data > .title .pivot .name {
  relative: top left;
  z-index: 8;
  font-size: 1.2em;
  padding: 5px 0;
  font-family: triunity condensed, sans-serif;
  color: #545454;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.goal-data > .title .pivot .name .icon {
  background-color: rgba(84,84,84,0.33);
  padding: 4px;
  margin-right: 5px;
  border-radius: 50%;
  width: 1.1em;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  text-shadow: none;
}
.goal-data > .title .pivot .icons {
  float: right;
  relative: top left;
  z-index: 11;
}
.goal-data > .title .pivot .icons * {
  display: inline-block;
  margin-left: 7px;
  vertical-align: middle;
}
.goal-data > .title .pivot .icons .info {
  color: #fff;
  border-radius: 50%;
  background: #808080;
  padding: 0;
  text-align: center;
  line-height: 1.6em;
  width: 1.6em;
}
.goal-data > .title .pivot .icons .info .icon-info {
  font-size: 1em;
  padding: 0;
  margin: 0;
}
.goal-data > .title .pivot .icons .todo {
  background: #808080;
  border-radius: 50%;
  color: #fff;
  padding: 0 5px;
  line-height: 1.8em;
  width: 1.1em;
  text-align: center;
  font-size: 0.9em;
}
.goal-data > .title .pivot .icons .status {
  color: #fff;
  display: none;
  animation: pop;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}
.goal-data > .title .pivot .icons .status:before {
  font-family: FontAwesome;
}
.goal-data > .title .pivot .icons .clock {
  font-size: 20px;
  color: #808080;
}
.goal-data > .title .pivot .icons .clock .time {
  font-size: 0.9em;
  color: #5a5a5a;
  padding-top: 4px;
}
.goal-data > .steps {
  color: #fff;
  max-height: 0;
  overflow: hidden;
  margin: 0 3px 0 9px;
  border-radius: 0 0 4px 10px;
  transition: 0.85s;
}
.goal-data > .steps .step {
  padding: 10px;
  color: #000;
  background: #fff;
  cursor: pointer;
  border-bottom: dotted 1px #929292;
}
.goal-data > .steps .step:last-child {
  border-bottom: none;
}
.goal-data > .steps .step .name {
  margin-top: 14px;
}
.goal-data > .steps .step .movie,
.goal-data > .steps .step .quiz,
.goal-data > .steps .step .link,
.goal-data > .steps .step .file {
  float: left;
  margin-right: 10px;
  height: 60px;
  width: 80px;
  background: #a1a1a1;
  color: #fff;
  text-align: center;
  border-radius: 4px 10px;
  overflow: hidden;
}
.goal-data > .steps .step .movie i,
.goal-data > .steps .step .quiz i,
.goal-data > .steps .step .link i,
.goal-data > .steps .step .file i {
  line-height: 60px;
  font-size: 35px;
}
.goal-data > .steps .step .time {
  font-size: 0.9em;
}
.goal-data > .steps .step .status {
  display: inline-block;
  width: 20px;
  float: left;
  height: 50px;
  padding-top: 20px;
  margin-right: 5px;
}
.goal-data > .steps .step .icons {
  float: right;
}
.goal-data > .steps .step .icons > * {
  display: inline-block;
  margin-left: 10px;
}
.goal-data > .steps .step.submited {
  background: linear-gradient(to top, rgba(30,114,127,0.89), #94e0e1);
  border: 1px solid #08c8bf;
  outline: 1px solid #67dfe5;
}
.goal-data > .steps .step.completed {
  color: #585858;
  background: #dedede;
  border-bottom: 1px solid #fff;
}
.goal-data > .steps .step.completed .status i:before {
  content: '\e834';
}
.goal-data.inactive > .title .pivot .progress .bar {
  width: 100% !important;
  background-color: #808080 !important;
}
.goal-data.inactive > .title .pivot .name {
  color: #fff;
}
.goal-data.inactive > .title .pivot .name .icon[style] {
  color: #808080 !important;
}
.goal-data.inactive > .title .pivot .status {
  display: inline-block;
}
.goal-data.inactive > .title .pivot .status:before {
  content: '\e895';
}
.goal-data.inactive > .title .pivot .todo {
  display: none;
}
.goal-data.complete > .title .pivot .todo {
  display: none;
}
.goal-data.complete > .title .pivot .status {
  display: inline-block;
}
.goal-data.complete > .title .pivot .status:before {
  content: '\e834';
}
.goal-data.open > .steps {
  max-height: 9000px;
  transition: 0.45s;
}
.goal-data.open > .title .pivot {
  background: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.2) 30%, rgba(0,0,0,0));
  background-color: #f2eded;
  transform: translate3d(0, 0, 0);
  transform: rotateX(30deg);
}
.goal-data.open > .title .pivot .progress .bar {
  background: linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.4) 30%, rgba(0,0,0,0.1));
}
.goal-course {
  padding: 10px 0;
  margin-bottom: 25px;
}
.goal-course .course-title {
  font-family: triunity condensed, sans-serif;
  margin-top: -20px;
  background: #4f4f4f;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #fff;
  padding: 10px;
  border-radius: 4px 10px;
}
.goal-course .course-title .icons {
  float: right;
  cursor: pointer;
}
.goal-course .course-title .icons .info {
  color: #808080;
  border-radius: 50%;
  background: #fff;
  padding: 2px 9px 0px 9px;
  text-align: center;
  line-height: 1.6em;
  font-size: 13px;
}
.goal-course .course-title .icons .info .icon-info {
  font-size: 1em;
  padding: 0;
  margin: 0;
}
.community-item {
  position: relative;
  padding: 5px;
  height: auto;
  border-radius: 5px 20px;
  margin-bottom: 3px;
}
.community-item .picture {
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 50%;
  border: 5px solid #fff;
  margin-right: 10px;
}
.community-item .picture img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.community-item .picture span {
  font-size: 50px;
  text-align: center;
  width: 80px;
  display: inline-block;
  margin-top: 13px;
}
.community-item .name {
  font-weight: bold;
  display: inline-block;
  padding-top: 5px;
}
.community-item .relation {
  font-weight: bold;
  display: inline-block;
}
.community-item .date {
  font-size: 0.9em;
}
.community-item .phone {
  font-weight: bold;
}
.community-item .icon {
  display: inline-block;
  background: #b3b3b3;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  text-align: center;
  color: #e4e4e4;
  text-decoration: none;
  border: 1px solid #e4e4e4;
  margin-left: 5px;
  line-height: 21px;
}
.community-item .buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.community-item .delete {
  position: absolute;
  top: 10px;
  right: 10px;
}
.community-item.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  margin-left: -280px;
  background-clip: padding-box;
  background-color: #eee;
}
.community-item.modal .modal-header .buttons {
  position: absolute;
  right: 10px;
  bottom: auto;
  top: auto;
}
.plan-overview relative,
.analytics relative,
.plan-overview .gtky,
.analytics .gtky,
.plan-overview .inventory,
.analytics .inventory {
  background: linear-gradient(35deg, #7eabb1, #bedadd, #7eabb1);
  background-size: cover;
  min-height: 250px;
  padding: 20px 10px 30px 30px;
}
.plan-overview relative iframe,
.analytics relative iframe,
.plan-overview .gtky iframe,
.analytics .gtky iframe,
.plan-overview .inventory iframe,
.analytics .inventory iframe {
  margin: 0 auto;
}
.plan-overview relative .headline h1,
.analytics relative .headline h1,
.plan-overview .gtky .headline h1,
.analytics .gtky .headline h1,
.plan-overview .inventory .headline h1,
.analytics .inventory .headline h1 {
  font-size: 38px;
  line-height: 40px;
  color: #fff;
  border-bottom: none;
}
.plan-overview relative .headline p,
.analytics relative .headline p,
.plan-overview .gtky .headline p,
.analytics .gtky .headline p,
.plan-overview .inventory .headline p,
.analytics .inventory .headline p {
  color: #4c4c4c;
}
.plan-overview relative .headline .btn,
.analytics relative .headline .btn,
.plan-overview .gtky .headline .btn,
.analytics .gtky .headline .btn,
.plan-overview .inventory .headline .btn,
.analytics .inventory .headline .btn {
  border-radius: 4px 10px;
  padding: 10px 40px;
  color: #4c4c4c;
  margin-bottom: 5px;
  background: linear-gradient(rgba(250,250,250,0.5), rgba(250,250,250,0.2), rgba(250,250,250,0.5));
}
.plan-overview relative .headline .btn .icon,
.analytics relative .headline .btn .icon,
.plan-overview .gtky .headline .btn .icon,
.analytics .gtky .headline .btn .icon,
.plan-overview .inventory .headline .btn .icon,
.analytics .inventory .headline .btn .icon {
  padding: 6px 5px 6px 8px;
  margin-left: 10px;
  border-radius: 50%;
  background: rgba(0,0,0,0.161);
}
.plan-overview relative .headline .btn:hover,
.analytics relative .headline .btn:hover,
.plan-overview .gtky .headline .btn:hover,
.analytics .gtky .headline .btn:hover,
.plan-overview .inventory .headline .btn:hover,
.analytics .inventory .headline .btn:hover {
  box-shadow: 0 0 12px #5878c1;
}
.plan-overview .pill,
.analytics .pill {
  background: #fff;
  width: 100%;
  padding: 10px;
  color: #4c4c4c;
}
.plan-overview .pill h3,
.analytics .pill h3 {
  margin: 5px 0;
  line-height: 25px;
}
.plan-overview .pack .name,
.analytics .pack .name {
  padding: 10px;
  background: #fff;
  font-weight: bold;
  font-size: 16px;
  border-top-right-radius: 19px;
}
.plan-overview .pack .bottomButton,
.analytics .pack .bottomButton {
  float: right;
  text-align: center;
  padding: 8px 10px 6px;
  line-height: 11px;
  font-size: 0.7em;
  background-color: #aeaeae;
  border-radius: 4px 8px;
  color: #fff;
}
.plan-overview .pack .body.clearfix,
.analytics .pack .body.clearfix {
  padding: 0;
  background: #fff;
}
.plan-overview .category,
.analytics .category {
  text-align: left;
  min-height: 20px;
  cursor: pointer;
  background: none;
  padding: 12px 10px;
  width: 100%;
  float: left;
  position: relative;
  box-sizing: border-box;
}
.plan-overview .category .circle-20,
.analytics .category .circle-20 {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.plan-overview .category .tab,
.analytics .category .tab {
  float: left;
  top: 3px;
  left: 0;
  width: 30px;
  bottom: 5px;
  position: absolute;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px 0px 8px 10px;
  box-shadow: inset -2px -2px 7px rgba(0,0,0,0.48);
}
.plan-overview .category .name,
.analytics .category .name {
  font-weight: bold;
  font-size: 0.9em;
  line-height: 1em;
  padding-top: 0.25em;
  text-overflow: ellipsis;
  color: #fff;
  overflow: hidden;
}
.plan-overview .category .date,
.analytics .category .date {
  font-weight: bold;
  font-size: 0.6em;
  line-height: 0.7em;
  padding-top: 0.25em;
  text-overflow: ellipsis;
  color: #fff;
  overflow: hidden;
  white-space: pre;
  text-align: right;
}
.plan-overview .category .progress,
.analytics .category .progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  height: 100%;
  z-index: 9;
  border-radius: 0;
}
.plan-overview .category .progress .bar,
.analytics .category .progress .bar {
  overflow: hidden;
  padding: 12px 0 0 10px;
  text-align: left;
  border-radius: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0.1) 30%, rgba(0,0,0,0.4));
  box-shadow: inset -2px -2px 7px rgba(0,0,0,0.48);
}
.plan-overview .category .progress .bar .name,
.analytics .category .progress .bar .name {
  text-overflow: clip;
  white-space: pre;
}
.plan-overview .graph,
.analytics .graph {
  width: 100%;
  height: 200px;
  background: #fff;
  border-left: solid 1px #ccc;
  padding-left: 20px;
  padding-bottom: 10px;
}
.plan-overview .graph .nvtooltip,
.analytics .graph .nvtooltip {
  color: #999;
  border-radius: 2px 10px;
}
.plan-overview .filters,
.analytics .filters {
  position: relative;
}
.plan-overview .filters select,
.analytics .filters select {
  margin: 0;
  margin-right: 10px;
  display: inline-block;
  width: 45%;
}
.plan-overview .slider,
.analytics .slider {
  padding: 0px 25px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.plan-overview .focus .body,
.analytics .focus .body {
  text-align: center;
}
.plan-overview .focus .chart,
.analytics .focus .chart {
  position: relative;
  display: inline-block;
  width: 221px;
  height: 225px;
}
.plan-overview .focus .chart svg,
.analytics .focus .chart svg {
  width: 260px;
  height: 260px;
}
.plan-overview .focus .chart img,
.analytics .focus .chart img {
  position: absolute;
  top: 14px;
  left: 14px;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  border: solid 1px #898989;
  border-bottom-color: rgba(255,255,255,0.43);
  border-right-color: rgba(129,129,129,0.43);
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
.plan-overview .focus .catName,
.analytics .focus .catName {
  margin: 10px 0;
  text-align: left;
}
.plan-overview .focus .catName .dot,
.analytics .focus .catName .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 3px;
  margin-right: 10px;
  box-shadow: 1px 1px 2px;
}
.plan-overview .challenge,
.analytics .challenge,
.plan-overview .school-info,
.analytics .school-info {
  box-sizing: border-box;
  padding-top: 15px !important;
  background: linear-gradient(to bottom, #4a4a4a, #231f20);
  height: 70px;
  color: #fff;
  font-size: 0.9em;
  line-height: 1em;
  vertical-align: top;
  font-family: "triunity condensed";
}
.plan-overview .challenge .items,
.analytics .challenge .items,
.plan-overview .school-info .items,
.analytics .school-info .items {
  margin: 0 auto;
  width: 240px;
}
.plan-overview .challenge .items .item,
.analytics .challenge .items .item,
.plan-overview .school-info .items .item,
.analytics .school-info .items .item {
  float: left;
  height: 40px;
}
.plan-overview .challenge .items .number,
.analytics .challenge .items .number,
.plan-overview .school-info .items .number,
.analytics .school-info .items .number {
  font-size: 60px;
  line-height: 40px;
  padding: 5px;
}
.plan-overview .challenge .items .text,
.analytics .challenge .items .text,
.plan-overview .school-info .items .text,
.analytics .school-info .items .text {
  font-size: 22px;
  line-height: 45px;
  height: 22px;
  vertical-align: middle;
}
.plan-overview .challenge .items .big,
.analytics .challenge .items .big,
.plan-overview .school-info .items .big,
.analytics .school-info .items .big {
  line-height: 23px;
}
.plan-overview .challenge .items .info,
.analytics .challenge .items .info,
.plan-overview .school-info .items .info,
.analytics .school-info .items .info {
  margin-left: 10px;
}
.plan-overview .challenge .items .center-text,
.analytics .challenge .items .center-text,
.plan-overview .school-info .items .center-text,
.analytics .school-info .items .center-text {
  font-size: 22px;
  line-height: 25px;
  text-align: center;
}
.plan-overview .challenge .items .center-text .big,
.analytics .challenge .items .center-text .big,
.plan-overview .school-info .items .center-text .big,
.analytics .school-info .items .center-text .big {
  font-size: 25px;
  font-weight: bold;
}
.plan-overview .ilri-list .category-ring,
.analytics .ilri-list .category-ring {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0;
  width: 130px;
  margin-left: 15px;
}
.plan-overview .ilri-list .category-ring .wcircle,
.analytics .ilri-list .category-ring .wcircle {
  margin-left: 15px;
}
.plan-overview .ilri-list .category-ring .name,
.analytics .ilri-list .category-ring .name {
  text-align: center;
  font-size: 1em;
  padding: 5px 5px 2px 5px;
  border-radius: 20px;
  background: #c5c5c5;
  color: #666;
  box-shadow: inset 1px 1px 5px;
  border-bottom: 1px solid rgba(255,255,255,0.43);
  margin-left: 15px;
}
.plan-overview .years-filter,
.analytics .years-filter {
  position: absolute;
  left: 60px;
  right: 40px;
  bottom: 15px;
}
stats {
  display: block;
}
stats svg .nvd3 .nv-axis .nv-axisMaxMin text {
  font-weight: 500;
}
.badgeSection {
  padding: 15px;
  border-bottom: solid 1px #ccc;
}
.badgeSection .title {
  color: #8a8a8a;
}
.user-files.prison {
  background: #333232;
  border-radius: 0;
}
.user-files.prison .title {
  background: linear-gradient(to bottom, #d6d2d2, #ede8e8);
  padding: 10px;
  border-radius: 4px 20px;
  font-family: triunity condensed, sans-serif;
  color: #8a8a8a;
  margin-left: -10px;
  margin-right: -2px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.5);
}
.user-files.prison .item {
  font-family: triunity condensed, sans-serif;
  color: #8a8a8a;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.user-files.prison .item.active {
  cursor: pointer;
}
.user-files.prison .item * {
  display: inline-block;
}
.user-files.prison .item .name {
  font-size: 0.9em;
  color: #8a8a8a;
}
.user-files.prison .item .date {
  font-size: 0.9em;
  float: right;
  color: #8a8a8a;
}
.user-files.prison .item .icon {
  font-size: 1.2em;
  margin-right: 10px;
}
.product-view .modal-body {
  padding: 0 0 10px 0;
}
.journal .not-seen:before {
  position: absolute;
  content: '';
  background: #49d058;
  top: -2px;
  bottom: -2px;
  left: -10px;
  width: 25px;
  box-shadow: -2px 1px 3px #81f584;
  border-radius: 4px 10px;
}
.journal .inline-list .activite .filter {
  top: 0;
}
.journal .date-header-line {
  border-top: 1px solid #989898;
  border-bottom: 1px solid #cacaca;
  background: rgba(0,0,0,0.08);
  margin: 0 -15px 10px;
  padding: 3px 20px;
  font-family: "triunity condensed";
  color: rgba(255,255,255,0.72);
  font-size: 1em;
}
.journal .date-header-line .holder {
  width: 80px;
  height: 20px;
  margin-top: 5px;
}
.journal .date-header-line .day {
  transform: rotate(-90deg);
  transform-origin: left bottom;
  width: 1.7em;
  overflow: hidden;
  float: left;
  margin-left: 20px;
  margin-right: -30px;
  padding-left: 0.09em;
  text-transform: uppercase;
  font-size: 0.8em;
  text-align: center;
}
.journal .date-header-line .dater {
  float: left;
  font-size: 28px;
  color: #cacaca;
}
.journal .date-header-line .month {
  float: left;
  line-height: 0.98em;
  font-size: 0.8em;
  margin-left: 3px;
  margin-top: -0.1em;
  text-transform: uppercase;
}
.journal .filter-container {
  background-image: linear-gradient(to bottom, rgba(92,92,92,0.36), rgba(0,0,0,0.41) 66%, rgba(29,29,29,0.91));
  white-space: nowrap;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 77px;
  border-top: 1px solid rgba(219,219,219,0.73);
  color: #fff;
  padding: 5px;
}
.journal .filter-container .filter {
  margin: 5px;
  position: relative;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  width: initial;
  background: none;
  width: 200px;
  float: right;
}
.journal .filter-container .header {
  padding: 2px 5px;
}
.journal .filter-container .header .icon-users {
  font-size: 0.9em;
  padding: 3px 5px;
  top: -6px;
  left: 30px;
}
.journal .filter-container .header select {
  height: 20px;
  font-size: 0.9em;
  line-height: 1.1em;
  padding-top: 3px;
  background: rgba(233,233,233,0.42);
  border: 1px solid rgba(252,252,252,0.32);
  border-top-color: #525252;
  margin-right: 6px;
}
.journal .filter-container .body {
  position: absolute;
  top: 30px;
  left: 10px;
  right: 10px;
  bottom: 0;
}
.journal .filter-container .title {
  float: left;
  font-family: triunity condensed;
}
.journal .filter-container .journal-filter-item {
  float: left;
  text-align: center;
  margin-right: 5px;
  width: 36px;
  white-space: normal;
}
.journal .filter-container .journal-filter-item .filter-item {
  margin: 0 auto;
  border: 2px solid #424242;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.journal .filter-container .journal-filter-item .selected {
  background: #47e442;
}
filter + .journal-entry {
  margin-top: 40px;
}
.journal-entry {
  padding: 0;
  background: #333232;
  margin-bottom: 10px;
  border-radius: 4px 20px;
  margin-left: 20px;
  margin-right: 4px;
  border: none;
}
.journal-entry .entry-details {
  position: relative;
  border-bottom: none;
  background: linear-gradient(to bottom, #d6d2d2, #ede8e8);
  padding: 10px;
  border-radius: 4px 20px;
  min-height: 50px;
  margin-left: -20px;
  margin-right: -4px;
  box-shadow: 0 2px 3px rgba(0,0,0,0.35);
}
.journal-entry .entry-details.needs-grading {
  background: linear-gradient(45deg, #eaffae 0%, #90c57b 100%);
  animation: flow;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  background-size: 400% 400%;
}
.journal-entry .entry-details > .icon {
  color: #808080;
  font-size: 20px;
  float: left;
  margin-right: 15px;
  padding: 10px;
  background: linear-gradient(to top, #d6d2d2, #d4d4d4);
  border-radius: 50%;
  text-align: center;
  border: solid 2px #bbb;
  width: 25px;
}
.journal-entry .entry-details > .icon i {
  display: inline-block;
}
.journal-entry .entry-details > .icon.icon-SESSION_REPORT {
  padding: 0;
  width: 30px;
  border: 2px solid #fff;
  margin-top: 2px;
}
.journal-entry .entry-details > .icon.icon-SESSION_REPORT img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.journal-entry .picture,
.journal-entry .resource-holder {
  position: relative;
  width: 30px;
  height: 30px;
  float: left;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-right: 15px;
  margin-bottom: 10px;
}
.journal-entry .picture img,
.journal-entry .resource-holder img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.journal-entry .picture > span,
.journal-entry .resource-holder > span {
  font-size: 20px;
  text-align: center;
  width: 30px;
  display: inline-block;
  margin-top: 4px;
}
.journal-entry .picture .resource,
.journal-entry .resource-holder .resource {
  display: inline-block;
  position: absolute;
  bottom: -13px;
  right: -13px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 2px 2px 5px #4d4d4d;
  text-align: center;
  width: 26px;
  height: 26px;
}
.journal-entry .picture i,
.journal-entry .resource-holder i {
  text-decoration: none;
  line-height: 26px;
  font-size: 15px;
  color: #585858;
}
.journal-entry .resource-holder {
  position: absolute;
  width: 26px;
  height: 26px;
  border: none;
  top: 30px;
  left: 40px;
}
.journal-entry .resource-holder .resource {
  position: absolute;
  bottom: -5px;
  right: 0;
}
.journal-entry .content-holder {
  padding-right: 25px;
  margin-left: 15px;
}
.journal-entry .content-holder .difficulty {
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  padding: 0;
  font-size: 0.9em;
  font-weight: bold;
  color: #000;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.journal-entry .content-holder .color-1 {
  background: #450;
  color: #fff;
}
.journal-entry .content-holder .color-2 {
  background: #769400;
  color: #fff;
}
.journal-entry .content-holder .color-3 {
  background: #add800;
  color: #000;
}
.journal-entry .content-holder .color-4 {
  background: #dee54d;
  color: #000;
}
.journal-entry .content-holder .color-5 {
  background: #f4f321;
}
.journal-entry .content-holder .color-6 {
  background: #ebb37a;
}
.journal-entry .content-holder .color-7 {
  background: #e5994d;
}
.journal-entry .content-holder .color-8 {
  background: #ff5a5a;
}
.journal-entry .content-holder .color-9 {
  background: #e80000;
}
.journal-entry .content-holder .color-10 {
  background: #c00000;
}
.journal-entry .content-holder .title {
  font-weight: bold;
  display: inline-block;
  border-bottom: none !important;
  padding-top: 5px !important;
  margin: 8px 10px 0 0 !important;
  padding: 0 !important;
}
.journal-entry .content-holder .action {
  font-style: italic;
  font-weight: normal;
}
.journal-entry .content-holder .date {
  font-size: 0.9em;
  display: inline-block;
  font-style: italic;
}
.journal-entry .content-holder .goal-ring {
  position: relative;
  width: 30px;
  height: 30px;
  background: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}
.journal-entry .content-holder .goal-ring:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  border: 4px solid #c0c0c0;
}
.journal-entry .content-holder .ilries {
  clear: both;
  margin-left: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.journal-entry .content-holder .ilries .dot {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}
.journal-entry .buttons {
  text-align: right;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 25px;
  padding: 10px;
}
.journal-entry .buttons .icon {
  display: inline-block;
  background: none;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  text-align: center;
  color: #ababab;
  text-decoration: none;
  line-height: 21px;
  margin-bottom: 5px;
  fontsize: 20px;
}
.journal-entry .buttons .icon:hover {
  color: #000;
}
.journal-entry-comment {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  padding: 5px;
  color: #ddd;
}
.journal-entry-comment.foreign {
  padding-left: 20px;
}
.journal-entry-comment.foreign .picture {
  float: right;
  margin-right: 60px;
}
.resource-list > .head {
  background: #ccc;
  background: linear-gradient(to bottom, #484848, #242122);
  box-sizing: border-box;
  overflow: hidden;
}
.resource-list > .head .text {
  color: #fff;
  padding: 3px 15px 4px;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
}
.resource-list > .list {
  border: none;
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  bottom: 0;
}
.resource-list > .list > .goal {
  display: block;
  width: auto;
  height: auto;
  margin: 0;
}
.resource-list > .list > .goal > .name {
  height: 34px;
  line-height: 34px;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.05), rgba(0,0,0,0.2));
  text-align: left;
  padding: 0 10px;
  color: #fff;
}
.resource-list > .list .resource {
  border-bottom: 1px solid #ccc;
  background: linear-gradient(to bottom, #e6e5e5, #fff);
  color: #333;
  position: relative;
  cursor: pointer;
  min-height: 80px;
}
.resource-list > .list .resource:hover {
  background: linear-gradient(to right, #b1c1bb, #83e0b1) !important;
  color: #fff !important;
}
.resource-list > .list .resource:hover::before {
  font-family: FontAwesome;
  content: '\e8c4';
  position: absolute;
  top: 15px;
  left: 0px;
  color: #fff;
  font-size: 50px;
  animation: pulsate 1.5s infinite linear;
  z-index: 500;
}
.resource-list > .list .resource .circle {
  display: block;
  position: absolute;
  top: 14px;
  left: 10px;
  width: 16px;
  height: 16px;
  border: 3px solid #000;
  border-radius: 50%;
}
.resource-list > .list .resource .circle:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  speak: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: transparent;
}
.resource-list > .list .resource .type {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 7px;
  margin-top: 30px;
  font-size: 22px;
}
.resource-list > .list .resource .content {
  margin: 0 34px 0 34px;
  padding: 5px;
  overflow: hidden;
}
.resource-list > .list .resource .content h1 {
  display: block;
  font-size: 15px;
  line-height: 1.8em;
  margin: 0;
  padding: 10px 0 0 0;
  font-weight: normal;
  min-height: 40px;
  overflow: hidden;
}
.resource-list > .list .resource .content .icons {
  float: left;
}
.resource-list > .list .resource .content .icons i {
  margin-right: 5px;
}
.resource-list > .list .resource .content .messages {
  font-size: 0.9em;
}
.resource-list > .list .resource .content .messages .completed {
  float: right;
}
.resource-list > .list .resource.completed {
  border-bottom: 1px solid #e0e0e0;
  background: linear-gradient(to bottom, #b0b0b0, #cacaca);
  color: #fff;
}
.resource-list > .list .resource.completed .circle:before {
  content: "\e833 ";
  color: #038c05;
  position: absolute;
  top: 1px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.resource-list > .list .resource.completed .line {
  display: block;
  position: absolute;
  top: 0;
  height: 15px;
  left: 18px;
  border-left: solid 5px;
}
.resource-list > .list .resource.completed a.link {
  background-color: #bfbfbf;
}
.resource-list > .list .resource.completed:after {
  content: '';
  position: absolute;
  top: 35px;
  left: 18px;
  display: block;
  bottom: -1px;
  border-left: solid 5px;
  border-color: inherit;
}
.resource-list > .list .resource.selected {
  background: linear-gradient(to bottom, #706e6e, #6d6d6d, #424141) !important;
  color: #fff !important;
}
.resource-list > .list .resource.selected .circle {
  border-color: #fff;
}
.resource-list > .list .resource.selected .circle:before {
  color: #fff;
}
.resource-list > .list .completed + .resource,
.resource-list > .list .name + .resource {
  background: linear-gradient(to bottom, #e6e5e5, #fff);
  color: #333;
}
.resource-list > .list .completed + .resource .circle,
.resource-list > .list .name + .resource .circle {
  top: 10px;
  left: 5px;
  width: 20px;
  height: 20px;
  border: 5px solid #000;
}
.resource-list > .list .completed + .resource .circle:before,
.resource-list > .list .name + .resource .circle:before {
  content: "\e8c4";
  position: absolute;
  top: 3px;
  left: 3px;
  speak: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.resource-list > .list .completed + .resource .line,
.resource-list > .list .name + .resource .line {
  display: block;
  position: absolute;
  top: 0;
  height: 15px;
  left: 18px;
  border-left: solid 5px;
}
.resource-list > .list .completed + .resource.completed,
.resource-list > .list .name + .resource.completed {
  border-bottom: 1px solid #e0e0e0;
  background: linear-gradient(to bottom, #b0b0b0, #cacaca);
  color: #fff;
}
.resource-list > .list .completed + .resource.completed .circle,
.resource-list > .list .name + .resource.completed .circle {
  position: absolute;
  top: 14px;
  left: 10px;
  width: 16px;
  height: 16px;
  border: 3px solid;
}
.resource-list > .list .completed + .resource.completed .circle:before,
.resource-list > .list .name + .resource.completed .circle:before {
  content: "\e833";
  color: #038c05;
  position: absolute;
  top: 1px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.resource-list.body {
  padding: 0;
}
.resource-list .sections .accordion-group {
  border-radius: 0;
  margin: 0;
}
.resource-list .sections .accordion-group .accordion-heading {
  background: linear-gradient(to bottom, #e7e7e7, #f1f1f1 26%, #fff);
}
.resource-list .sections .accordion-group .accordion-heading .accordion-toggle {
  text-decoration: none;
  color: #fff;
  padding: 0;
}
.resource-list .sections .accordion-group .accordion-heading .accordion-toggle::before {
  font-family: FontAwesome;
  content: '\e8c4';
  color: #cac2c2;
  display: inline-block;
  text-align: center;
  font-size: 1.2em;
  padding: 10px 8px 0 10px;
  margin-right: 3px;
  margin-top: -1px;
  float: left;
}
.resource-list .sections .accordion-group .accordion-heading .accordion-toggle .title {
  padding: 10px;
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.3));
}
.resource-list .sections .accordion-group.expanded .accordion-heading .accordion-toggle::before {
  content: '\e8c6';
}
.resource-list .sections .accordion-group .accordion-inner {
  padding: 0;
}
.resource-viewer.away .pane {
  bottom: 0;
  z-index: 400;
  border-bottom: solid 1px #969696;
}
.resource-viewer.away .pane > ng-include > .body,
.resource-viewer.away .pane > ng-include > .wrapper > .body {
  top: 0;
  bottom: 33px;
  border-top: solid 1px #969696;
  transition: all 0.2s;
  background: #fff;
  padding: 0;
  bottom: 33px;
}
.resource-viewer .pane {
  padding: 0px;
}
.resource-viewer .pane .buttons {
  position: absolute;
  bottom: 35px;
  left: 50%;
  z-index: 7000;
  margin-left: -100px;
  border: solid 1px rgba(136,136,136,0.34);
  border-radius: 4px 15px;
  box-shadow: #373737 0px 0;
}
.resource-viewer .pane .buttons .btn-default {
  background-color: rgba(0,0,0,0.64);
  color: #fff;
  background-image: none;
  border-color: #4d4d4d;
}
.resource-viewer .btn:hover {
  background-color: rgba(27,194,0,0.64);
  color: #375e31;
}
.resource-viewer .viewer.quiz-view {
  text-align: left;
  background-color: #fff;
}
.resource-viewer iframe.link-frame {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}
.iframe-container {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
}
.iframe-container iframe.link-frame {
  width: 100%;
  height: 100%;
  border: none;
}
.modal.learning,
.modal.learning.fade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  margin: 0;
  border-radius: 0;
  overflow: initial;
}
.modal.learning .modal-body,
.modal.learning.fade .modal-body {
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  min-height: initial;
  max-height: initial;
}
.suggestion {
  background: linear-gradient(to bottom, #777, #bdbdbd, #595959);
  border-radius: 10px;
  margin-bottom: 10px;
  color: #fff;
}
.suggestion .author {
  padding: 10px;
  float: left;
  width: 50px;
}
.suggestion .text {
  padding: 10px;
  margin-left: 70px;
  margin-right: 250px;
}
.suggestion .lessons {
  padding: 10px;
  width: 150px;
  float: right;
}
.suggestion .rate {
  float: right;
  padding: 10px;
  padding-top: 30px;
  width: 60px;
}
.suggestion .rate a {
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}
.suggestion .rate a:hover {
  text-decoration: none;
}
.student .details {
  padding: 10px;
  float: left;
  width: 150px;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 1em;
  color: #939393;
  line-height: 1em;
  margin-left: -10px;
  margin-top: -10px;
}
.student .details .circle-50 {
  margin-bottom: 10px;
}
.student .goals {
  padding: 10px;
  margin-left: 150px;
}
.ilri .user-details {
  font-weight: bold;
  font-weight: lighter;
  background: #565656;
  background: linear-gradient(to bottom, #777, #595959, #2e2e2e);
  padding: 10px 10px 4px;
  -webkit-border-radius: 4px 10px;
  border-radius: 4px 10px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 1em;
}
.ilri .skills {
  background-color: rgba(0,0,0,0.6);
  color: #fff;
  padding: 20px;
  border-radius: 4px 10px;
}
.ilri .skills .category {
  font-weight: bold;
}
.ilri .skills .grid {
  margin-top: 20px;
  border-radius: 4px 10px;
}
.ilri .skills .grid .title {
  font-weight: bold;
  background: #4f4f4f linear-gradient(to right, rgba(255,255,255,0.34), rgba(255,255,255,0.28) 50%, rgba(255,249,249,0));
  padding: 4px 10px;
}
.ilri .comment {
  background: #282626;
  margin-top: 20px;
  color: #fff;
}
.ilri .comment .title {
  font-weight: bold;
  background: #4f4f4f linear-gradient(to right, rgba(255,255,255,0.34), rgba(255,255,255,0.28) 50%, rgba(255,249,249,0));
  border-radius: 4px 4px 0 0;
  padding: 4px 10px;
}
.ilri .comment textarea {
  width: 100%;
  height: 200px;
}
.ilri.left-column,
.ilri .categories {
  padding-top: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.ilri.left-column .category,
.ilri .categories .category {
  text-align: left;
  padding: 10px;
  min-height: 40px;
  cursor: pointer;
  margin-left: 0px;
  margin-bottom: 5px;
  background: #ccc;
  padding: 10px 10px 4px;
  border-radius: 4px 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5) inset, 0 1px 2px rgba(0,0,0,0.5);
  width: 258px;
  float: left;
}
.ilri.left-column .category .circle-30,
.ilri .categories .category .circle-30 {
  float: left;
  margin: 0 15px 0px 0px;
}
.ilri.left-column .category .name,
.ilri .categories .category .name {
  font-weight: bold;
  font-size: 0.9em;
  line-height: 14px;
  padding-top: 5px;
}
.ilri.left-column .category.selected,
.ilri .categories .category.selected {
  color: #ddd;
  background-color: rgba(0,0,0,0.6);
}
filter[name="ilri"] .btn-info + .tooltip,
filter[name="schools"] .btn-info + .tooltip,
filter[name="products"] .btn-info + .tooltip {
  left: -200px !important;
  top: 23px !important;
}
filter[name="ilri"] .btn-info + .tooltip .tooltip-arrow,
filter[name="schools"] .btn-info + .tooltip .tooltip-arrow,
filter[name="products"] .btn-info + .tooltip .tooltip-arrow {
  display: none;
}
.collage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
}
.collage .collage-item {
  position: absolute;
  cursor: move;
  user-select: none;
  border: 1px solid transparent;
}
.collage .collage-item .image {
  width: 100%;
}
.collage .collage-item.active {
  box-shadow: 2px 2px 13px #000;
  border: 1px solid #fff;
}
.collage .collage-item.active .handle-resize,
.collage .collage-item.active .handle-rotate,
.collage .collage-item.active .button-remove {
  display: block;
}
.collage .collage-item .handle-resize {
  position: absolute;
  bottom: -19px;
  right: -14px;
  transform: rotateY(180deg);
  cursor: move;
  display: none;
}
.collage .collage-item .handle-resize i {
  text-shadow: 0px 0px 4px #fff;
}
.collage .collage-item .handle-rotate {
  position: absolute;
  bottom: -18px;
  left: -14px;
  transform: rotate(-50deg);
  cursor: move;
  display: none;
}
.collage .collage-item .handle-rotate i {
  text-shadow: 0px 0px 4px #fff;
}
.collage .collage-item .button-remove {
  cursor: pointer;
  position: absolute;
  top: -18px;
  right: -14px;
  display: none;
}
.collage .collage-item .button-remove i {
  text-shadow: 0px 0px 4px #fff;
}
.collage-view {
  left: 20px;
  right: 20px;
  margin-left: 0;
  width: auto;
}
.collage-view .modal-body {
  position: relative;
  min-height: 500px;
}
.gallery-image {
  display: inline-block;
  width: 160px;
  height: 110px;
  padding: 5px;
  text-align: center;
  border: 1px solid #808080;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.gallery-image .icons {
  position: absolute;
  top: 0;
  right: 0;
}
.gallery-image .select-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: #62c462;
  color: #fff;
  width: 18px;
  height: 18px;
  border: 1px solid #cfcfcf;
  border-radius: 20%;
  padding: 2px 0 0 2px;
}
.gallery-image.selected .select-icon {
  display: block;
}
.left-column .pane {
  background-color: rgba(0,0,0,0.4);
  background: url("/src/images/bg.jpg");
  border-radius: 0;
}
.left-column .btn {
  border: 1px solid #868686;
  border-bottom: 1px solid #fff;
}
.syllabi .pack .header .campus {
  color: #454545;
  font-style: italic;
  font-size: 0.9em;
  line-height: 0.9em;
}
.switch {
  position: absolute;
  z-index: 9999;
}
.addItem {
  position: fixed;
  bottom: 30px;
  z-index: 1000;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.25);
  border-radius: 50%;
}
.addItem .btn {
  padding: 4px 1px;
}
.addItem .dropdown-menu {
  top: auto;
  bottom: 30px;
}
.angular-ui-tree-nodes {
  padding: 5px !important;
}
.problem {
  border: solid 2px #24c2ff !important;
  box-shadow: 0 0 7px #00fff3;
}
.angular-ui-tree-node {
  background: #fff;
  margin: 2px 0;
  padding-bottom: 2px;
  border: 1px solid #dae2ea;
  border-radius: 4px 2px 0 0;
  display: inline-block;
}
.angular-ui-tree-node.FREE_ILRI-li {
  border: solid 1px #e2e2e2;
  padding: 10px;
  border-radius: 10px;
  margin-top: 5px;
  display: block;
  background: #f7f7f7;
}
.angular-ui-tree-node.FREE_ILRI-li .EXISTING_ILRI-li {
  margin-right: 10px !important;
}
.angular-ui-tree-node.CAT_CONTENT-li {
  border: none;
  padding: 0px;
  border-radius: 0px;
  margin: 5px -10px;
  display: block;
}
.angular-ui-tree-node.CAT_CONTENT-li >.children {
  min-height: 150px;
}
.angular-ui-tree-node.CATEGORY-li,
.angular-ui-tree-node.EXISTING_CATEGORY-li {
  border: solid 2px #89a989;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
  display: block;
}
.angular-ui-tree-node.ILRI_COL-li,
.angular-ui-tree-node.INS_COL-li {
  background: none;
  float: left;
  display: inline-block;
  width: 260px;
}
.angular-ui-tree-node.LESSON-li {
  float: left;
  padding: 10px;
  background: #abc4cc;
  border-radius: 10px;
}
.angular-ui-tree-node.STEPS-li,
.angular-ui-tree-node.add_ILRI-li,
.angular-ui-tree-node.add_INRES-li,
.angular-ui-tree-node.add_RESOURCE-li {
  background: none;
  padding: 5px;
  border: 3px dotted #fff;
  border-radius: 10px;
  margin: 12px 5px;
  width: 220px;
  color: rgba(255,255,255,0.451);
  font-size: 1.1em;
  font-weight: bold;
}
.angular-ui-tree-node.req_ILRI-li,
.angular-ui-tree-node.req_QUIZ-li,
.angular-ui-tree-node.req_RESOURCE-li {
  background: none;
  padding: 5px;
  border: 3px dotted #e42424;
  border-radius: 10px;
  width: 210px;
  margin-bottom: 5px;
}
.angular-ui-tree-node.req_ILRI-li,
.angular-ui-tree-node.req_QUIZ-li,
.angular-ui-tree-node.req_RESOURCE-li,
.angular-ui-tree-node.add_ILRI-li,
.angular-ui-tree-node.add_INRES-li,
.angular-ui-tree-node.add_RESOURCE-li {
  text-align: center;
}
.angular-ui-tree-node a.btn.dropdown-toggle {
  font-size: 15px;
  padding: 4px 45px;
  margin-bottom: 5px;
}
.angular-ui-tree-node.WORD-li,
.angular-ui-tree-node.FILE-li,
.angular-ui-tree-node.LINK-li,
.angular-ui-tree-node.COLLAGE-li,
.angular-ui-tree-node.VIDEO_LINK-li,
.angular-ui-tree-node.EXISTING_QUIZ-li,
.angular-ui-tree-node.QUIZ-li,
.angular-ui-tree-node.PLAN-li {
  width: 238px;
  border-radius: 10px;
  padding: 5px;
  border: 2px solid #8d9cab;
  background: #fff;
}
.angular-ui-tree-node.STEP-li {
  border-radius: 10px;
  padding: 10px 5px;
  width: 238px;
}
.angular-ui-tree-node.STEP-li .FILE-li,
.angular-ui-tree-node.STEP-li .LINK-li,
.angular-ui-tree-node.STEP-li .COLLAGE-li,
.angular-ui-tree-node.STEP-li .VIDEO_LINK-li,
.angular-ui-tree-node.STEP-li .EXISTING_QUIZ-li,
.angular-ui-tree-node.STEP-li .QUIZ-li {
  width: 234px;
  margin: 0 -7px;
  padding: 2px;
}
.angular-ui-tree-node.STEP-li .FILE-li .title,
.angular-ui-tree-node.STEP-li .LINK-li .title,
.angular-ui-tree-node.STEP-li .COLLAGE-li .title,
.angular-ui-tree-node.STEP-li .VIDEO_LINK-li .title,
.angular-ui-tree-node.STEP-li .EXISTING_QUIZ-li .title,
.angular-ui-tree-node.STEP-li .QUIZ-li .title {
  font-size: 0.9em;
  line-height: 1.3em;
  color: #999;
}
.angular-ui-tree-node.STEP-li .FILE-li .title.border,
.angular-ui-tree-node.STEP-li .LINK-li .title.border,
.angular-ui-tree-node.STEP-li .COLLAGE-li .title.border,
.angular-ui-tree-node.STEP-li .VIDEO_LINK-li .title.border,
.angular-ui-tree-node.STEP-li .EXISTING_QUIZ-li .title.border,
.angular-ui-tree-node.STEP-li .QUIZ-li .title.border {
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  color: #338a9a;
}
.angular-ui-tree-node.STEP-li .FILE-li .title.borderRed,
.angular-ui-tree-node.STEP-li .LINK-li .title.borderRed,
.angular-ui-tree-node.STEP-li .COLLAGE-li .title.borderRed,
.angular-ui-tree-node.STEP-li .VIDEO_LINK-li .title.borderRed,
.angular-ui-tree-node.STEP-li .EXISTING_QUIZ-li .title.borderRed,
.angular-ui-tree-node.STEP-li .QUIZ-li .title.borderRed {
  margin-bottom: 20px;
  border-bottom: 1px solid #df1b17;
  background: #fff8f1;
}
.angular-ui-tree-node.ILRI_COL-li,
.angular-ui-tree-node.INS_COL-li {
  border: none;
}
.angular-ui-tree-node.ILRI_COL-li .title,
.angular-ui-tree-node.INS_COL-li .title {
  font-size: 1.2em;
  color: #fff;
}
.angular-ui-tree-node.QUIZ_QUESTION-li,
.angular-ui-tree-node.EXISTING_ILRI_PLACED-li,
.angular-ui-tree-node.EXISTING_ILRI-li {
  border-radius: 10px;
  background-color: #97bdd4;
  padding: 3px;
  margin: 5px -4px;
  width: 228px;
  border: 2px solid #8d9cab;
}
.angular-ui-tree-node.QUIZ_QUESTION-li .title,
.angular-ui-tree-node.EXISTING_ILRI_PLACED-li .title,
.angular-ui-tree-node.EXISTING_ILRI-li .title {
  font-size: 0.85em;
  line-height: 1.5em;
  font-weight: bold;
  color: #fff;
}
.angular-ui-tree-node.open {
  min-height: 60px;
}
.angular-ui-tree-node [class*=" QUIZ_QUESTION"] {
  width: 200px;
}
.angular-ui-tree-node.editor {
  width: 100%;
}
.angular-ui-tree-node.editor a.btn.dropdown-toggle {
  font-size: 12px;
  padding: 3px 8px;
  margin-bottom: 0;
}
.angular-ui-tree-node.editor li[class*="QUIZ_QUESTION"],
.angular-ui-tree-node.editor li[class*="ANSWER"],
.angular-ui-tree-node.editor li[class*="PLAN_ENTRY"],
.angular-ui-tree-node.editor li.LESSON-li {
  width: 100%;
  margin: 2px -7px;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #8d9cab;
}
.angular-ui-tree-node.editor li[class*="QUIZ_QUESTION"] .node-head,
.angular-ui-tree-node.editor li[class*="ANSWER"] .node-head,
.angular-ui-tree-node.editor li[class*="PLAN_ENTRY"] .node-head,
.angular-ui-tree-node.editor li.LESSON-li .node-head {
  width: 100%;
}
.angular-ui-tree-node.editor.LESSON-li {
  width: 100%;
  margin: 2px -7px;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #8d9cab;
}
.angular-ui-tree-node .dropdown-menu.pull-right {
  bottom: 40px;
  top: inherit;
}
.angular-ui-tree-node .info {
  font-size: 0.75em;
  color: #b9b9b9;
  position: relative;
  top: -13px;
  left: 54px;
  clear: left;
}
.angular-ui-tree-node .cat {
  font-size: 17px;
  line-height: 17px;
  padding: 4px 10px 3px 10px;
  margin: 0 -5px 10px -5px;
  border-bottom: 1px solid #e0e0e0;
  color: #636363;
}
.angular-ui-tree-node input,
.angular-ui-tree-node richtext,
.angular-ui-tree-node textarea {
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.angular-ui-tree-node input.cat,
.angular-ui-tree-node richtext.cat,
.angular-ui-tree-node textarea.cat {
  padding: 0 10px 3px 10px;
  margin: 0 -5px 10px -5px;
  color: #338a9a;
}
.angular-ui-tree-node input.basic,
.angular-ui-tree-node richtext.basic,
.angular-ui-tree-node textarea.basic {
  font-size: 14px;
  line-height: 14px;
  padding: 0 10px 0px 45px;
  margin: 0 -5px 12px -5px;
  border-bottom: 1px solid #e0e0e0;
}
.angular-ui-tree-node input.step,
.angular-ui-tree-node richtext.step,
.angular-ui-tree-node textarea.step {
  font-size: 16px;
  line-height: 14px;
  padding: 0 10px 0px 2px;
  margin: 0 -5px 12px -5px;
  border-bottom: 1px solid #e0e0e0;
  width: 185px;
}
.angular-ui-tree-node input .new,
.angular-ui-tree-node richtext .new,
.angular-ui-tree-node textarea .new {
  background: #9ef95f;
  padding: 4px;
}
.angular-ui-tree-node input .revise,
.angular-ui-tree-node richtext .revise,
.angular-ui-tree-node textarea .revise {
  background: #f9e95f;
  padding: 4px;
}
.angular-ui-tree-node input .remove,
.angular-ui-tree-node richtext .remove,
.angular-ui-tree-node textarea .remove {
  background: #ff8080;
  padding: 4px;
  text-decoration: line-through;
}
.angular-ui-tree-node input .simplify,
.angular-ui-tree-node richtext .simplify,
.angular-ui-tree-node textarea .simplify {
  background: #73eefd;
  padding: 4px;
}
.angular-ui-tree-node input .annotation,
.angular-ui-tree-node richtext .annotation,
.angular-ui-tree-node textarea .annotation {
  text-decoration: underline #43acb3 dashed;
}
.angular-ui-tree-node input .annotation::after,
.angular-ui-tree-node richtext .annotation::after,
.angular-ui-tree-node textarea .annotation::after {
  content: '\e881';
  display: inline-block;
  speak: none;
  font-family: FontAwesome;
  position: relative;
  bottom: -3px;
  font-size: 8px;
  left: 2px;
  color: #43acb3;
}
.angular-ui-tree-node input .richtext,
.angular-ui-tree-node richtext .richtext,
.angular-ui-tree-node textarea .richtext {
  min-height: 30px;
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.angular-ui-tree-node input .richtext .display,
.angular-ui-tree-node richtext .richtext .display,
.angular-ui-tree-node textarea .richtext .display {
  margin: 5px;
  max-height: inherit;
  border: none;
  min-height: 35px;
}
.angular-ui-tree-node input .richtext .placeholder,
.angular-ui-tree-node richtext .richtext .placeholder,
.angular-ui-tree-node textarea .richtext .placeholder {
  color: #8f8f8f;
  font-weight: 400;
}
.angular-ui-tree-node input .editing,
.angular-ui-tree-node richtext .editing,
.angular-ui-tree-node textarea .editing {
  min-height: 150px;
}
.angular-ui-tree-node .angular-ui-tree-handle {
  display: block;
  padding: 2px;
  font-weight: 400;
}
.angular-ui-tree-node .node-head {
  padding: 0 5px;
  position: relative;
}
.angular-ui-tree-node.CATEGORY-head {
  background: #fff !important;
  margin-top: -20px;
}
.angular-ui-tree-node.invalid {
  box-shadow: 0 0 3px #fd2a2a;
}
.angular-ui-tree-node .icon {
  color: #b3b3b3;
  padding: 5px;
  position: relative;
  border: 2px solid;
  top: -4px;
  left: -4px;
  border-radius: 50%;
  font-size: 14px;
  background: #f1f1f1;
  margin-right: 5px;
}
.angular-ui-tree-node .title {
  padding: 8px;
  font-size: 1.5em;
  line-height: 1.1em;
  font-weight: bold;
}
.angular-ui-tree-node .node-type .row {
  margin: 0;
}
.angular-ui-tree-node .node-type .row [class*="span"] {
  margin-left: 0;
}
.angular-ui-tree-node .stamp {
  position: absolute;
  font-size: 10px;
  right: 35px;
  bottom: 3px;
  color: #aaa;
}
.angular-ui-tree-node .expander {
  width: 15px;
  height: 20px;
  padding: 4px;
  color: #949494;
  font-size: 22px;
  text-decoration: none;
}
.angular-ui-tree-node .state {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
}
.angular-ui-tree-node .state.sub {
  background: linear-gradient(to bottom, #62c462, #8cc63e);
}
.angular-ui-tree-node .state.rev {
  background: linear-gradient(to bottom, #ff0a0a, #ffc00f);
}
.angular-ui-tree-node .state.app {
  background: linear-gradient(to bottom, #62bfc4, #3e55c6);
}
.angular-ui-tree-node .node-buttons {
  position: absolute;
  right: 6px;
  top: 6px;
}
.angular-ui-tree-node .node-buttons .dropdown-toggle {
  border-radius: 4px;
  border-radius: 4px 0px 0px 10px;
  border-right: none;
}
.angular-ui-tree-node .btn-mini.pull-right {
  position: absolute;
  right: -2px;
  top: -2px;
  border-radius: 0 10px 4px 0;
  color: #b3b3b3;
  padding: 7px;
  font-size: 12px;
}
.angular-ui-tree-node .btn-mini.pull-right.bottom {
  right: 23px;
  border-radius: 4px 0 0 10px;
}
.angular-ui-tree-node .icon-picker .preview,
.angular-ui-tree-node .color-picker .preview {
  width: 10px;
  height: 10px;
  line-height: 0.75em;
  border-radius: 50%;
  margin: 0;
}
.angular-ui-tree-node .icon-picker .preview [class*="icon"],
.angular-ui-tree-node .color-picker .preview [class*="icon"] {
  font-size: 0.8em;
  line-height: 0.8em;
  vertical-align: top;
}
.angular-ui-tree-node .LESSON-body {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}
.angular-ui-tree-node .node-content {
  margin-top: 5px;
  margin-bottom: -10px;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  box-sizing: border-box;
  clear: both;
  min-height: 100px;
  margin: 15px 0px 1px;
}
.group-title {
  background-color: #687074 !important;
  color: #fff !important;
}
.angular-ui-tree-empty {
  display: none;
}
.top-bar {
  position: absolute;
  top: 0;
  left: 50%;
  background: rgba(0,0,0,0.2);
  padding: 5px 15px;
  border-radius: 0 0 10px 10px;
  color: #fff;
  font-size: 1.3em;
  line-height: 1.3em;
  border: 1px solid #c8c8c8;
  border-top: none;
}
.draftList .draft {
  padding: 10px;
  color: #5a5a5a;
  background: linear-gradient(to bottom, #e7e7e7, #f1f1f1 26%, #fff);
  cursor: pointer;
  border: solid 1px;
  border-top-color: #dcdcdc;
  border-bottom-color: #a7a7a7;
  border-left-color: #aeaeae;
  border-right-color: #b6b6b6;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
}
.draftList .draft:first-of-type {
  border-radius: 4px 15px 0px 0px;
}
.draftList .draft:last-of-type {
  border-radius: 0px 0px 4px 15px;
}
.draftList .draft:hover {
  background: #dad9d9;
}
.course {
  color: #333;
}
.course .header .name {
  width: 85%;
  margin-bottom: 8px;
}
.course .header .details {
  float: left;
  color: #898989;
  margin-top: -3px;
  font-size: 0.9em;
  background: rgba(210,210,210,0) linear-gradient(to right, rgba(255,255,255,0.44), rgba(255,255,255,0.3) 70%, rgba(255,249,249,0));
  padding: 3px 40px 3px 10px;
  position: relative;
  border-radius: 0 4px 10px 0;
  margin-left: -8px;
}
.course .header .details .field {
  display: inline-block;
  margin-right: 10px;
}
.course .header .details .field > * {
  vertical-align: middle;
}
.course .frame .padding {
  padding: 3px 15px;
  line-height: 1.1em;
}
.course .left-column {
  float: left;
  width: 280px;
}
.course .right-column {
  margin-left: 300px;
}
.course .teacher .name {
  margin-left: 5px;
  font-size: 1em;
}
.course .teacher .image {
  width: 40px;
  height: 40px;
}
.course .teacher .state {
  color: #008000;
  float: right;
  margin-right: 10px;
  font-family: FontAwesome;
  font-weight: normal;
}
.course .teacher .state::before {
  content: '\e834';
}
.course .teacher.waiting .state {
  color: #808080;
}
.course .teacher.waiting .state::before {
  content: '\e8b9';
}
.course .teacher.rejected .state {
  color: #b31f1f;
}
.course .teacher.rejected .state::before {
  content: '\e895';
}
.course .emails .flag {
  width: 30px;
}
.course .emails .date {
  float: right;
  margin-right: 20px;
}
.course .emails .message {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #808080;
}
.course .student {
  min-height: 50px !important;
}
.course .student .name {
  float: left;
  margin-left: 10px;
  margin-top: 15px;
  font-size: 1em;
}
.course .student .image {
  float: left;
  width: 40px;
  height: 40px;
}
.course .frame .list-item {
  background: linear-gradient(to right, #b6b6b6, #abaaaa);
  border-radius: 4px 10px;
  padding: 3px;
  color: #fff;
  min-height: 30px;
}
.course .frame .list-item .buttons {
  float: right;
}
.course .frame .list-item .student-goals,
.course .frame .list-item .student-achievements {
  text-align: right;
}
.course .frame .list-item.expandable .head {
  cursor: pointer;
}
.course .frame .list-item.expandable .body {
  display: none;
  background: linear-gradient(to bottom, rgba(255,255,255,0.14), rgba(255,255,255,0.53));
  color: #333;
  padding: 10px;
}
.course .frame .list-item.expandable.open .body {
  display: block;
}
.course.preview .left-column {
  width: 160px;
  margin: -10px;
  background: #b8b8b8;
  margin-bottom: 30px;
}
.course.preview .right-column {
  position: relative;
  margin-left: 180px;
  margin-right: 30px;
}
.course.preview .teacher {
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
  margin: 10px;
  min-width: 110px;
}
.course.preview .teacher .tooltip {
  z-index: 1;
  transition: 0.65s;
  overflow: hidden;
  width: 0px;
  padding-left: 70px;
}
.course.preview .teacher .tooltip.in {
  width: 400px;
  transition: 0.65s;
}
.course.preview .teacher .tooltip[style] {
  top: 0 !important;
  left: 60px !important;
}
.course.preview .teacher .tooltip .tooltip-inner {
  border-radius: 0 10px 4px;
}
.course.preview .teacher .tooltip .tooltip-arrow {
  top: 5px;
  left: 0px;
  width: 70px;
  height: 110px;
  background: linear-gradient(to right, rgba(0,0,0,0.03) 20%, #000);
  border: none;
}
.course.preview .teacher .image {
  text-align: center;
  position: relative;
  width: 100%;
  perspective: 600px;
  padding-top: 115px;
  z-index: 2;
}
.course.preview .teacher .image::after {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  background: linear-gradient(to right, #aba7a7, #000 10%, #c8c8c8 80%, #d0d0d0);
  box-shadow: 1px 1px 3px #525252;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 1;
}
.course.preview .teacher .image.viewing img {
  transform: rotateY(-30deg);
}
.course.preview .teacher .image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  transition: 0.65s;
  z-index: 100;
  position: absolute;
  top: 5px;
  left: 5px;
  max-width: inherit;
}
.course.preview .teacher .name {
  color: #333;
  text-align: center;
  display: block;
}
.course.preview .teacher .tooltip-inner {
  padding: 15px;
  text-align: left;
  white-space: normal;
  width: 350px;
  max-width: 350px;
  min-height: 90px;
  border-radius: 4px 10px;
}
.subdes {
  color: #ccc;
  font-weight: normal;
}
.course-edit .deactivate {
  float: right;
}
.course-edit .open-btn {
  display: inline-block;
  height: 25px;
  padding-top: 5px;
}
.course-edit .open-from-date {
  width: 200px;
  line-height: 30px;
  margin-left: 30px;
}
.course-edit .open-from-date .text {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.course-edit .open-from-date input {
  width: 100px;
  margin-bottom: 0;
}
.course-edit .open-to-date {
  width: 140px;
  line-height: 30px;
}
.course-edit .open-to-date .text {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.course-edit .open-to-date input {
  width: 100px;
  margin-bottom: 0;
}
.student_summary.body {
  padding: 0;
}
.student_summary .sections .accordion-group {
  border-radius: 0;
  margin: 0;
}
.student_summary .sections .accordion-group .accordion-heading {
  background: linear-gradient(to bottom, #e7e7e7, #f1f1f1 26%, #fff);
}
.student_summary .sections .accordion-group .accordion-heading .accordion-toggle {
  text-decoration: none;
  color: #fff;
  padding: 10px 0;
}
.student_summary .sections .accordion-group .accordion-heading .accordion-toggle::before {
  font-family: FontAwesome;
  content: '\e8c4';
  color: #cac2c2;
  display: inline-block;
  text-align: center;
  font-size: 1.2em;
  padding: 0 8px;
  margin-right: 3px;
  margin-top: -1px;
  float: left;
}
.student_summary .sections .accordion-group.expanded .accordion-heading .accordion-toggle::before {
  content: '\e8c6';
}
.student_summary .sections .accordion-group .accordion-inner {
  padding: 0;
}
.student_summary .sections .accordion-group.courses .accordion-heading {
  background-color: #035900;
}
.student_summary .sections .accordion-group.certs .accordion-heading {
  background-color: #003147;
}
.student_summary .sections .accordion-group.skills .accordion-heading {
  background-color: #050427;
}
.student_summary .sections .accordion-group.files .accordion-heading {
  background-color: #054a49;
}
.student_summary .category {
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #9e9e9e;
  padding: 5px;
  z-index: 500;
  color: #eee;
}
.student_summary .category .circle-30 {
  float: left;
  margin: 0 15px 0px 0px;
}
.student_summary .category .compltePercent {
  color: #383838;
  font-size: 0.9em;
}
.student_summary .category .key {
  width: 100%;
  background-color: #bfbfbf;
  padding: 2px 10px;
  line-height: 0.98em;
  margin: 0 -4px -4px -4px;
  font-size: 0.85em;
  color: #313131;
}
.student_summary .courses .course .info {
  position: relative;
  cursor: pointer;
  background: linear-gradient(to bottom, #e6e5e5, #fff);
}
.student_summary .courses .course .info a.link {
  display: block;
  position: absolute;
  top: 13px;
  height: 30px;
  right: 8px;
  width: 30px;
  border-radius: 40%;
  border: 3px solid rgba(255,255,255,0.66);
  box-shadow: 0px 0px 3px rgba(0,0,0,0.45);
  background: rgba(0,0,0,0.3) url("/src/images/steps/arrow.png") 55% 50% no-repeat;
  background-size: 25%;
}
.student_summary .courses .course .info a.link:hover {
  background: rgba(0,0,0,0.7) url("/src/images/steps/arrow.png") 55% 50% no-repeat;
  background-size: 30%;
}
.student_summary .courses .course .info .content {
  margin: 0 30px 0 0px;
  padding: 5px 18px 5px 5px;
}
.student_summary .courses .course .info .content h1 {
  display: block;
  font-size: 15px;
  line-height: 1.8em;
  margin: 0;
  padding: 8px 0 0 0;
  font-weight: bold;
  min-height: 50px;
  font-family: triunity condensed;
}
.student_summary .files .file .date {
  width: 50px;
}
.student_summary .files .file .icon {
  padding: 0;
  width: 30px;
  color: #333;
}
.student_summary .files .file .icon a {
  background: #969696;
  border-radius: 3px;
  padding: 5px 5px 3px 5px;
  color: #fff;
  text-decoration: none;
}
.instructor .left-column {
  float: left;
  width: 240px;
}
.instructor .right-column {
  margin-left: 260px;
}
.instructor .block {
  margin-bottom: 20px;
}
.instructor .image {
  text-align: center;
}
.instructor .image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  text-align: center;
  box-shadow: 1px 1px 3px #525252;
}
.instructor .details th {
  padding-right: 10px;
  vertical-align: top;
  text-align: right;
}
.instructor .biography {
  margin-bottom: 30px;
}
.instructor .achievements .pill {
  text-align: left;
}
.instructor .courses .pill {
  text-align: left;
}
.instructor .courses.active .pill {
  background-color: #595959;
}
.instructor .courses.inactive .pill {
  background-color: #bababa;
}
.email-form .richtext .display {
  min-height: 400px;
  max-height: 400px;
}
.welcome .wrapper {
  background: url("/src/images/content-bg.gif") repeat;
}
.welcome .wrapper .modal-body {
  color: #fff;
  padding: 20px;
  background: url("/src/images/welcome.jpg") no-repeat top right;
  background-size: cover;
}
.welcome h1,
.welcome h2 {
  margin-top: 25px;
  border: none;
  color: #fff;
}
.welcome hr {
  border-top: none;
  border-bottom: 1px solid rgba(255,255,255,0.36);
}
.welcome a.icon {
  background: rgba(255,255,255,0.65);
  padding: 5px 5px 1px 7px;
  border-radius: 4px 10px;
  text-decoration: none;
  color: #808080;
}
.welcome .second {
  color: #fff;
  font-size: 0.95em;
  line-height: 0.98em;
}
.welcome .secHead {
  color: rgba(255,255,255,0.5);
}
.welcome .btn-info {
  border: none;
  border-radius: 4px 10px;
  padding: 10px 40px;
  background: linear-gradient(rgba(250,250,250,0.5), rgba(250,250,250,0.2), rgba(250,250,250,0.5));
  color: #644720;
  margin-bottom: 10px;
}
.welcome .btn-info::after {
  font-family: FontAwesome;
  content: '\e8c4';
  color: rgba(0,0,0,0.3);
  display: inline-block;
  text-align: center;
  font-size: 1.2em;
  padding: 0px 15px;
  margin-right: 3px;
  float: right;
}
.instanceName {
  font-size: 28px;
  line-height: 30px;
  margin-top: 20px;
  color: #fff;
}
.instanceLogo {
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50% 50%;
  border: solid 5px #fff;
  overflow: hidden;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
}
.register.pane {
  bottom: 0;
  overflow: auto;
}
.register.pane h3 {
  font-size: 1.5em;
  line-height: 22px;
  color: #000;
}
.register.pane .pack {
  padding: 15px 15px 30px;
  color: #064002;
}
.register.pane .rightCol {
  border-left: solid 1px #fff;
  padding: 0 5px 0 15px;
}
.register.pane .body {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px 40px;
}
.register.pane input {
  margin-bottom: 5px;
  font-size: 0.9em;
}
.register.pane input[type="checkbox"] {
  margin-bottom: 6px;
  margin-right: 5px;
}
.register.pane label {
  font-size: 0.9em;
}
.register.pane .btn-warning {
  border-radius: 4px 10px;
}
.register.pane .teacher {
  display: inline-block;
  margin-bottom: 30px;
  margin: 10px;
}
.register.pane .teacher .name {
  color: #333;
  text-align: center;
  display: block;
  margin-left: 5px;
  font-size: 0.9em;
}
.register.pane .teacher .image {
  text-align: center;
  width: 100%;
  height: 108px;
}
.register.pane .teacher .image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  text-align: center;
  box-shadow: 1px 1px 3px #525252;
}
.register.pane select.ng-invalid,
.register.pane textarea.ng-invalid,
.register.pane input[type="text"].ng-invalid,
.register.pane input[type="password"].ng-invalid,
.register.pane input[type="datetime"].ng-invalid,
.register.pane input[type="datetime-local"].ng-invalid,
.register.pane input[type="date"].ng-invalid,
.register.pane input[type="month"].ng-invalid,
.register.pane input[type="time"].ng-invalid,
.register.pane input[type="week"].ng-invalid,
.register.pane input[type="number"].ng-invalid,
.register.pane input[type="email"].ng-invalid,
.register.pane input[type="url"].ng-invalid,
.register.pane input[type="search"].ng-invalid,
.register.pane input[type="tel"].ng-invalid,
.register.pane input[type="color"].ng-invalid,
.register.pane .uneditable-input.ng-invalid {
  background: rgba(255,255,255,0.65) !important;
  border: 1px solid rgba(223,27,23,0.52) !important;
  border-top: 1px solid #9b5e5c !important;
}
.btn-group-lg>.btn,
.btn-lg {
  padding: 10px 16px;
  font-size: 1em;
  line-height: 1.1em;
}
input[type="checkbox"] {
  margin-bottom: 6px;
  margin-right: 5px;
}
#category-filter {
  padding: 5px;
  padding-top: 50px;
}
#category-filter .head {
  color: #c1c1c1;
}
#category-filter .inner-head {
  color: #c1c1c1;
  margin: 12px -4px;
  font-size: 22px;
  padding: 5px 15px 10px;
  text-align: left;
  border-bottom: 1px solid #484848;
  text-shadow: #000 0 2px 2px;
  font-family: triunity condensed, sans-serif;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 60%, rgba(0,0,0,0.51));
}
#category-filter .category {
  list-style: none;
  margin: 0;
  margin-bottom: 5px;
  transition: 0.65s;
  perspective-origin: top;
  perspective: 600px;
}
#category-filter .category > li {
  display: block;
  background: linear-gradient(to bottom, #333, #000 10%, #c8c8c8 80%, #646464);
  border-radius: 4px 10px;
  perspective-origin: top;
  perspective: 600px;
  box-shadow: #000 0px 2px 3px;
}
#category-filter .category > li .pivot {
  text-align: left;
  cursor: pointer;
  color: #808080;
  font-family: triunity condensed, sans-serif;
  background: linear-gradient(to bottom, rgba(175,175,175,0.9), rgba(175,175,175,0) 80%);
  background-color: #f2eded;
  padding: 8px 10px;
  border-radius: 4px 10px;
  transition: 0.65s;
}
#category-filter .category > li .pivot .icon {
  padding: 4px;
  width: 1.1em;
  border-radius: 50%;
  color: #fff;
  text-shadow: 2px 0 2px rgba(0,0,0,0.48);
  box-shadow: inset 2px 0 5px rgba(0,0,0,0.48);
  display: inline-block;
  text-align: center;
}
#category-filter .category > li .pivot .checkbox {
  margin-right: -2px;
  margin-top: -2px;
}
#category-filter .category .checkbox {
  float: right;
}
#category-filter .category .checkbox a {
  padding: 0;
  width: 16px;
  color: inherit;
}
#category-filter .category .active-goals {
  display: inline-block;
  float: right;
  background: #008000;
  border-radius: 10px;
  margin-right: 7px;
  font-size: 0.9em;
  padding: 3px 3px 0;
  line-height: 1em;
  color: #fff;
  min-width: 1em;
  text-align: center;
  text-shadow: 2px 0 2px rgba(0,0,0,0.48);
  box-shadow: inset 2px 0 5px rgba(0,0,0,0.48);
}
#category-filter .category .slide {
  transition: 0.35s;
  max-height: 0;
  overflow: hidden;
  margin: 0 3px 0 9px;
  border-radius: 0 0 4px 10px;
}
#category-filter .category .slide ul {
  margin: 0;
}
#category-filter .category .slide ul li {
  display: block;
  font-family: triunity condensed, sans-serif;
  color: #c9c9c9;
  clear: both;
  cursor: pointer;
  padding: 5px 5px 5px 10px;
  background: linear-gradient(to top, rgba(136,136,136,0.89), #5f5f5f);
  background-color: inherit;
}
#category-filter .category .slide ul li .checkbox {
  margin-right: 3px;
}
#category-filter .category .slide ul li .checkbox a {
  color: #fff;
}
#category-filter .category .slide ul li.selected {
  color: #fff;
  background: #181818;
}
#category-filter .category.opened > li .pivot {
  transform: translate3d(0, 0, 0);
  transform: rotateX(30deg);
  background: linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(0,0,0,0.4) 30%, rgba(0,0,0,0.1));
  background-color: #f2eded;
  border-top: solid 1px #000;
  color: #676767;
}
#category-filter .category.opened .slide {
  max-height: 1000px;
  transition: 0.65s 0.35s;
}
#category-filter .category.selected > li .pivot {
  background-color: #333;
  color: #c1c1c1;
}
#category-filter .category.selected .slide ul li {
  color: #fff;
  background: #181818;
}
#category-filter .category.selected .slide ul li .icon-check-empty:before {
  content: '\e9cd';
}
.tick line {
  display: none;
}
.learningPath .user-details {
  font-weight: bold;
  font-weight: lighter;
  background: #565656;
  background: linear-gradient(to bottom, #777, #595959, #2e2e2e);
  padding: 10px 10px 4px;
  -webkit-border-radius: 4px 10px;
  border-radius: 4px 10px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 1em;
}
.learningPath .skills {
  color: #000;
}
.learningPath .skills .pane {
  background: #ccc linear-gradient(to right, rgba(255,255,255,0.28) 50%, rgba(255,249,249,0));
}
.learningPath .skills .category {
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #9e9e9e;
  padding: 5px;
  z-index: 500;
  color: #eee;
}
.learningPath .skills .category .circle-30 {
  float: left;
  margin: 0 15px 0px 0px;
}
.learningPath .skills .category .compltePercent {
  color: #383838;
  font-size: 0.9em;
}
.learningPath .skills .category .key {
  width: 100%;
  background-color: #bfbfbf;
  padding: 2px 10px;
  line-height: 0.98em;
  margin: 0 -4px -4px -4px;
  font-size: 0.85em;
  color: #313131;
}
.learningPath .skills .title {
  margin: 20px 0 5px 0;
  font-weight: bold;
  color: #454545;
  border-bottom: 1px solid #454545;
}
.learningPath .active {
  box-shadow: inset 0px 0px 5px #2a2a5d;
  border-radius: 10px;
}
.learningPath .comment {
  background: #fff;
  margin-top: 20px;
  color: #222;
}
.learningPath .comment .title {
  font-weight: bold;
  background: #4f4f4f linear-gradient(to right, rgba(255,255,255,0.34), rgba(255,255,255,0.28) 50%, rgba(255,249,249,0));
  border-radius: 4px 4px 0 0;
  padding: 4px 10px;
}
.learningPath .comment textarea {
  width: 100%;
  height: 200px;
}
.learningPath.left-column {
  width: 278px !important;
}
.learningPath.left-column .pane .head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px !important;
  color: #eee !important;
  background: #a6a6a6 !important;
  font-size: 16px !important;
  text-shadow: none !important;
}
.learningPath.left-column .view .body {
  padding: 40px 0 0 0;
}
.learningPath.right-column {
  left: 278px !important;
}
.learningPath.right-column .pane {
  top: 20px;
}
.learningPath.right-column .pane .filter {
  position: absolute;
  right: 0;
  left: inherit;
  top: 30px;
  margin: -5px;
}
.learningPath .categories {
  padding-top: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.learningPath .categories .category {
  text-align: left;
  min-height: 20px;
  cursor: pointer;
  background: #ccc linear-gradient(to bottom, rgba(255,255,255,0.28) 50%, rgba(255,249,249,0));
  padding: 5px 10px;
  width: 258px;
  float: left;
  position: relative;
}
.learningPath .categories .category.recomended:before {
  color: #3e3e3e;
  content: "\e81a";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  font-smoothing: antialiased;
  Float: right;
}
.learningPath .categories .category .circle-20 {
  float: left;
  margin: 0 15px 0px 0px;
}
.learningPath .categories .category .name {
  font-weight: bold;
  font-size: 0.9em;
  line-height: 14px;
  padding-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.learningPath .categories .category .progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  height: 100%;
  z-index: 9;
  border-radius: 0;
}
.learningPath .categories .category .progress .bar {
  overflow: hidden;
  padding: 5px 0 0 10px;
  text-align: left;
  border-radius: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0.1) 30%, rgba(0,0,0,0.4));
  box-shadow: inset -2px -2px 7px rgba(0,0,0,0.48);
}
.learningPath .categories .category .progress .bar .name {
  text-overflow: clip;
  white-space: pre;
}
.learningPath .categories .category .progress .bar .circle-20 {
  float: left;
  margin: -5px 15px 0px 0px;
}
.learningPath .categories .category.selected {
  color: #ddd;
  background-color: rgba(0,0,0,0.6);
}
.learningPath .categories .category.selected .progress .bar {
  box-shadow: inset -2px -2px 7px rgba(255,255,255,0.9);
}
.dreams .gtky {
  min-height: 500px;
  padding: 20px;
}
.dreams .gtky .circle {
  border: solid #fff 4px;
  margin: 20px auto;
  border: solid #fff 4px;
  border-radius: 50%;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  padding-top: 24px;
  padding-bottom: 20px;
  cursor: initial;
}
.dreams .gtky .vidhold {
  margin: 10px auto 0 auto;
  max-width: 675px;
}
.dreams .gtky .headline {
  left: 10px;
  top: 3px;
  position: relative;
  max-width: 50%;
  margin-top: 10px;
}
.dreams .gtky .headline p {
  color: #4c4c4c;
}
.dreams .gtky .btn {
  border-radius: 4px 10px;
  padding: 10px 40px;
  font-size: 20px;
  clear: both;
  margin: 20px auto;
  display: table;
}
.dreams .gtky .btn:hover {
  box-shadow: 0 0 12px #5878c1;
}
.dreams .gtky.lower {
  background: linear-gradient(35deg, #7eabb1, #bedadd, #7eabb1);
  padding: 20px 10px 30px 30px;
  min-height: 250px;
}
.dreams .gtky.lower .headline {
  position: inherit;
  width: 100%;
}
.dreams .gtky.lower .headline .btn {
  margin-top: inherit;
  clear: inherit;
  border-radius: 4px 10px;
  padding: 10px 40px;
  font-size: 20px;
  margin-top: 5px;
  background: radial-gradient(#fafafa 0%, rgba(193,237,241,0.5) 60%, rgba(255,255,255,0.03));
  background-size: 600% 400%;
  animation: flow;
  animation-direction: alternate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.dreams .gtky.lower .headline .btn .icon {
  padding: 6px 5px 6px 10px;
  margin-left: 10px;
  border-radius: 50%;
  background: rgba(0,0,0,0.161);
}
.dreams .gtky.lower .headline .btn:hover {
  box-shadow: 0 0 12px #5878c1;
}
.dreams .pill {
  background: #fff;
  padding: 5px 15px;
  color: #4c4c4c;
  width: inherit;
}
.dreams .pill h3 {
  margin: 5px 0;
  line-height: 25px;
}
.dreams .steps {
  text-align: center;
  color: #616161;
  padding: 10px;
  font-size: 20px;
  padding: 15px;
}
.dreams .steps b {
  font-size: 35px;
  line-height: 40px;
  color: #fff;
}
.dreams .chart {
  width: 100%;
  height: 500px;
  background: linear-gradient(to bottom, #dadada, #f7f7f7);
  border-radius: 0 0 4px 20px;
  padding: 0 0 20px 0;
}
.dreams .chart svg text {
  font-size: 12px;
  font-weight: bold;
}
.dreams_survey {
  max-width: 700px;
  margin: 0 auto;
}
.skills {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}
.skills .btn-info {
  padding: 10px 10px;
  margin: 20px 10px;
  border-radius: 4px 10px;
}
.skills .pill {
  WIDTH: inherit;
}
.inventory .circle {
  border: solid #fff 4px;
  border-radius: 50%;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  padding-top: 24px;
  padding-bottom: 20px;
  cursor: initial;
  float: left;
  margin-right: 15px;
}
.survey .question .heading {
  background: linear-gradient(35deg, #7eabb1, #bedadd, #7eabb1);
  background-size: cover;
  padding: 20px 10px 10px 30px;
  margin: -15px -15px 10px -15px;
  border-radius: 0;
  color: #4b4b4b;
  font-size: 20px;
  border-bottom: solid #fff 1px;
}
.survey .question .heading ::after {
  border-top: 10px solid #83afb5;
}
.survey .question .heading p {
  clear: both;
  padding: 10px 0;
}
.dreams_survey .skill {
  margin-bottom: 10px;
}
.dreams_survey i {
  font-size: 23px;
  line-height: 35px;
}
.dreams_survey .skillname {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 21px;
  line-height: 22px;
}
.dreams_survey .skills {
  border-bottom: solid 1px #ccc;
  padding-bottom: 17px;
}
.dreams_survey .skills .state {
  margin-top: 16px;
}
.dreams_survey .states {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  padding-top: 14px !important;
  color: #4899aa;
}
.dreams_survey .legend {
  font-size: 16px;
  color: #fff;
  text-align: center;
  width: 25%;
}
.dreams_survey .angular-ui-tree-placeholder {
  display: none;
}
.dreams_survey .key {
  position: fixed;
  bottom: 64px;
  left: 0;
  right: 0;
  padding: 10px;
  background: #ccc;
  z-index: 900;
}
.dreams_survey .key .answer {
  width: 32%;
  margin-right: 10px;
}
.dreams_survey .empty .angular-ui-tree-empty {
  display: block;
  min-height: 50px;
  border-radius: 4px 8px;
  background: transparent;
  background-image: none;
  border: dashed 2px #999;
}
.learning .there-is-more {
  bottom: 65px;
}
.dashboard .body .holder {
  background: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.statbox {
  text-align: center;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px 10px;
  background: #fff;
  box-shadow: #dfdfdf 0px 1px 10px 1px;
  height: 270px;
  margin-bottom: 10px;
}
.statbox.dark {
  background: #b3b3b3;
  color: #666;
}
.statbox.dark .statcircle {
  background: none;
  font-size: 25px;
  margin-top: -10px;
}
.statbox.dark .title,
.statbox.dark .numbers {
  color: #fff;
}
.statbox.dark .scrollGroup {
  height: 128px;
  margin-top: 10px;
  overflow: scroll;
  background: #fff;
  padding: 10px;
  border-radius: 4px 10px;
  font-size: 10px;
  line-height: 14px;
}
.statbox .title {
  font-size: 17px;
  color: #999;
}
.statbox .mainNum {
  font-size: 50px;
  line-height: 60px;
  color: #000;
}
.statbox .mainNum sup {
  top: -2em;
  padding-left: 2px;
}
.statbox .mainNum .units {
  font-size: 12px;
  color: #999;
}
.statbox .des {
  font-size: 10px;
  color: #000;
  font-style: italic;
}
.statbox .change {
  font-size: 15px;
  color: #39b54a;
}
.statbox .change.red {
  color: #f00;
}
.statbox .statcircle {
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  padding: 11px 13px;
  font-size: 19px;
  width: fit-content;
  margin: 20px auto;
}
.planning .gtky {
  background: linear-gradient(35deg, #7eabb1, #bedadd, #7eabb1);
  background-size: cover;
  min-height: 250px;
  padding: 20px 10px 30px 30px;
}
.planning .gtky .headline {
  margin-top: 40px;
  margin-bottom: 40px;
}
.planning .gtky .choices .item {
  position: relative;
  background: #fff;
  padding: 10px 31px;
  margin-bottom: 10px;
  border-radius: 4px 8px;
  margin-right: 32px;
}
.planning .gtky .choices .item:hover {
  background: #e0e0e0;
}
.planning .gtky .choices .item .circle {
  border-radius: 50%;
  padding: 7px;
  font-size: 24px;
  width: 26px;
  position: absolute;
  left: -15px;
  top: 0px;
}
.planning .gtky .choices .item .name {
  font-size: 14px;
}
.planning .gtky .explore {
  background: rgba(0,0,0,0.122);
  text-align: center;
  width: 55%;
  margin: 0 auto;
  padding: 3px;
  border-radius: 20px;
  font-size: 11px;
  cursor: pointer;
}
.planning .gtky .explore:hover {
  background: #000;
}
.whiteBox {
  background: #fff;
  padding: 10px 20px;
  border-radius: 4px 20px;
  width: unset;
  text-align: center;
}
.whiteBox .skillHold {
  height: 137px;
  overflow-x: visible;
  overflow-y: auto;
  margin-bottom: 10px;
}
.whiteBox .completedSkills {
  position: relative;
  margin-bottom: 7px;
}
.whiteBox .completedSkills .name {
  font-size: 11px;
  text-align: left;
  padding: 6px 10px;
  margin-left: 20px;
  background: #ececec;
  border-radius: 4px;
}
.whiteBox .completedSkills .small.circle {
  border-radius: 50%;
  padding: 3px;
  font-size: 11px;
  width: 19px;
  position: absolute;
  top: 0px;
  color: #fff;
}
.whiteBox .section {
  font-weight: bold;
  font-size: 16px;
}
.whiteBox .description {
  font-weight: normal;
  font-style: italic;
  font-size: 10px;
  color: #5d5d5d;
}
.whiteBox .buigNum {
  font-weight: bold;
  font-size: 96px;
  line-height: 100px;
  padding: 16px;
  padding-top: 32px;
  color: #88b2b8;
}
.whiteBox .stat {
  height: 200px;
  padding: 10px;
}
.lowerButton {
  background: linear-gradient(0deg, #4d4d4d, #959595 53%);
  width: 153px;
  text-align: center;
  color: #fff;
  float: left;
  padding: 9px;
  border-radius: 4px 8px;
  margin: 10px 5px 0 0;
  cursor: pointer;
}
.lowerButton:hover {
  background: #000;
}
.view.ng-enter {
  animation-name: view-enter;
  animation-duration: 500ms;
  animation-timing-function: ease;
}
.repeat-animation.ng-enter {
  opacity: 0;
  animation-name: view-enter;
  animation-duration: 1s;
  animation-timing-function: ease;
}
.repeat-animation.ng-enter .title .pivot .progress .bar {
  animation: progress 0.6s cubic-bezier(0.76, 0.75, 0.86, 1.24);
  animation-delay: 0.1s;
}
.repeat-animation.ng-enter .title .pivot .progress .bar[style] {
  width: 0 !important;
  transition: none;
}
.repeat-animation.ng-enter-stagger {
  animation-delay: 0.2s;
  animation-duration: 0s;
}
.repeat-animation.ng-enter-stagger .title .pivot .progress .bar {
  animation-delay: 5s;
}
.repeat-animation.ng-leave {
  display: none;
}
.view.ng-leave {
  display: none;
}
.modal.learning,
.question.anitmate {
  animation: slide-in 0.25s ease;
}
@-moz-keyframes view-enter {
  0% {
    transform: translate3d(5%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes view-enter {
  0% {
    transform: translate3d(5%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes view-enter {
  0% {
    transform: translate3d(5%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes view-enter {
  0% {
    transform: translate3d(5%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slide-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slide-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slide-out {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes slide-out {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@-o-keyframes slide-out {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slide-out {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@-moz-keyframes pulse {
  from {
    box-shadow: 0px -2px 10px 3px #cefdff;
  }
  to {
    box-shadow: 0px 2px 20px 5px #65f3f9;
  }
}
@-webkit-keyframes pulse {
  from {
    box-shadow: 0px -2px 10px 3px #cefdff;
  }
  to {
    box-shadow: 0px 2px 20px 5px #65f3f9;
  }
}
@-o-keyframes pulse {
  from {
    box-shadow: 0px -2px 10px 3px #cefdff;
  }
  to {
    box-shadow: 0px 2px 20px 5px #65f3f9;
  }
}
@keyframes pulse {
  from {
    box-shadow: 0px -2px 10px 3px #cefdff;
  }
  to {
    box-shadow: 0px 2px 20px 5px #65f3f9;
  }
}
@-moz-keyframes stretch {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes stretch {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-o-keyframes stretch {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes stretch {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-moz-keyframes flow {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 0% 40%;
  }
}
@-webkit-keyframes flow {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 0% 40%;
  }
}
@-o-keyframes flow {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 0% 40%;
  }
}
@keyframes flow {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 70%;
  }
  100% {
    background-position: 0% 40%;
  }
}
@-moz-keyframes progress {
  0% {
    width: 0;
  }
}
@-webkit-keyframes progress {
  0% {
    width: 0;
  }
}
@-o-keyframes progress {
  0% {
    width: 0;
  }
}
@keyframes progress {
  0% {
    width: 0;
  }
}
@-moz-keyframes pop {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0);
  }
  90% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes pop {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0);
  }
  90% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes pop {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0);
  }
  90% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pop {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0);
  }
  90% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}
.touch .grid table.list .icons {
  display: inline-block;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: "triunity";
  font-size: 14px;
  color: #333;
  overflow: hidden;
  background: url("/src/images/content-bg.gif") repeat;
  height: 100%;
}
.login .row-fluid >div {
  margin-bottom: 3px;
}
.log {
  position: absolute;
  color: #545454;
  font-size: 14px;
  left: 10px;
  top: 9px;
}
.city {
  background: radial-gradient(rgba(90,132,234,0.53) 0%, rgba(211,193,241,0.5) 60%, rgba(75,155,193,0.98));
  background-size: 400% 400%;
  width: 100%;
  height: 100%;
  animation: flow;
  animation-direction: alternate;
  animation-duration: 40s;
  animation-iteration-count: infinite;
}
body.inframe {
  background: none #fff;
}
body.inframe .lower-nav {
  display: none;
}
body.inframe #footer {
  display: none;
}
body.inframe #root {
  bottom: 0;
  top: 0;
}
body.inframe .city {
  background: none #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
body.inframe .pane .body {
  top: 0;
  padding: 0;
  margin: 0;
  color: #575757;
}
body.inframe .form-header {
  display: none;
}
th,
td {
  font-family: triunity condensed;
  font-size: 14px;
}
.btn {
  font-family: triunity condensed bold;
}
.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
}
.skip-link:focus {
  top: 0;
}
#header {
  height: 50px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #c5a2de;
  border-bottom: #fff solid 1px;
  z-index: 1000;
}
.nav {
  position: absolute;
  top: 16px;
  left: 20px;
  height: 45px;
}
.nav .name {
  display: inline-block;
  font-weight: normal;
  font-size: 25px;
  font-family: triunity;
}
.nav .icon {
  font-size: 15px;
}
.nav .circle-20 {
  padding: 3px;
  margin-right: 10px;
  margin-top: -7px;
}
.school {
  position: absolute;
  bottom: 0px;
  right: 75px;
  height: 45px;
}
.school .logo {
  float: left;
  margin-right: 10px;
  background: #fff;
  width: 41px;
  height: 41px;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 0;
  box-sizing: border-box;
}
.school .logo img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.school .name {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
  margin-top: 8px;
  font-family: triunity condensed bold;
  width: 120px;
  line-height: 15px;
}
.school .role {
  margin-top: 5px;
  font-size: 18px;
  color: #dcdbdd;
  white-space: nowrap;
}
.user {
  position: absolute;
  right: 65px;
  bottom: 0px;
  height: 45px;
}
.user a {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}
.user a:last-child::after {
  content: "\e8c6";
  display: inline-block;
  text-align: center;
  font-family: FontAwesome;
  font-size: 15px;
  margin-left: 8px;
}
.user .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.user .image-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #313d41;
}
.user .loader {
  position: absolute;
  left: 0px;
  top: 0px;
  background: url("/src/images/loaders/main.gif") no-repeat;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.user img {
  position: absolute;
  left: 3px;
  top: 3px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.user .name {
  margin-left: 60px;
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
  margin-top: 12px;
  min-width: 80px;
}
.user .type {
  font-size: 13px;
  color: #dcdbdd;
}
.appointments {
  background-image: linear-gradient(to bottom, #283c54, #4996b0 70%, #2f7999);
  width: 200px;
  padding: 40px 10px 10px 10px;
  position: absolute;
  top: 0;
  left: 50%;
  text-align: center;
  margin-left: -85px;
  border-radius: 0 0 10px 10px;
  border: #3b5a91 solid 1px;
  border-left: #f4f4f4 solid 1px;
  border-top: none;
  z-index: 1000;
}
.appointments a {
  text-decoration: none;
  color: #fff;
  padding: 3px;
}
.profile {
  margin-right: 75px;
  position: absolute;
  top: 0px;
  right: 50%;
  background: rgba(0,0,0,0.2);
  padding: 2px 8px;
  border-radius: 0 0 10px 10px;
}
.profile .image {
  float: left;
}
.profile .image img {
  width: 20px;
  height: 20px;
  border-width: 2px;
  margin-right: 5px;
}
.profile .name {
  color: #fff;
  float: left;
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 4px;
}
.btn {
  z-index: 500;
}
#breadcrumb {
  box-sizing: border-box;
  height: 22px;
  line-height: 22px;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, #484848, #242021);
  padding: 0px 15px;
  color: #dedede;
  font-size: 12px;
}
#content {
  box-sizing: border-box;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 20px;
  overflow: hidden;
}
.section_head {
  font-weight: bold;
  font-weight: lighter;
  background: #565656;
  background: linear-gradient(to bottom, #777, #595959, #2e2e2e);
  padding: 10px 10px 2px;
  border-radius: 4px 10px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
}
.infoBubble {
  text-align: center;
  padding: 3px 10px 0px 10px;
  border-radius: 20px;
  background: #c5c5c5;
  color: #666;
  border-bottom: 1px solid rgba(255,255,255,0.43);
  font-size: 10px;
}
#footer {
  box-sizing: border-box;
  height: 20px;
  line-height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, #484848, #242021);
  color: #dedede;
  font-size: 9px;
  padding: 0 15px;
}
#footer .donut {
  float: left;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 2px;
  box-sizing: border-box;
  margin: 7px 10px 0 0;
}
#footer .donut img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
#login .body {
  padding: 15px 50px;
}
#root > .register .form-header {
  margin-bottom: 24px;
}
#root > .register .form-header .logo img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}
#root > .register .form-header .name {
  color: #fff;
  text-transform: uppercase;
  font-size: 34px;
  line-height: 37px;
}
.container {
  margin: 0;
}
.left-column .filter {
  position: absolute;
  top: 0;
  left: 0;
  right: inherit;
  padding-left: 18px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 10px;
}
.pill {
  color: #f5f5f5;
  border: solid 1px transparent;
}
.html h1 {
  font-family: 'Ubuntu Condensed', sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #555;
  border: none;
  margin: 10px 0;
  padding: 0;
}
.html h2 {
  font-family: 'Ubuntu Condensed', sans-serif;
  font-size: 14px;
  line-height: 14px;
  color: #555;
  border: none;
  margin: 10px 0;
  padding: 0;
}
.html h3 {
  font-family: 'Ubuntu Condensed', sans-serif;
  font-size: 13px;
  line-height: 13px;
  color: #555;
  font-style: italic;
  font-weight: bold;
  border: none;
  margin: 10px 0;
  padding: 0;
}
.html p:last-child {
  margin-bottom: 0;
}
.three-column-layout {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
}
.three-column-layout > .body {
  top: 0;
}
.three-column-layout > .first-column {
  width: 250px;
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  z-index: 9;
  border-right: 1px solid #c8c8c8;
  transition: 0.25s;
  background: linear-gradient(to right, #e5e5e5 0%, #fff 7%) !important;
}
.three-column-layout > .first-column > .view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.three-column-layout > .first-column > .view .body {
  padding: 0;
}
.three-column-layout > .first-column .switcher {
  width: 25px;
  height: 40px;
  background: rgba(0,0,0,0.71);
  position: absolute;
  right: 0px;
  top: 7px;
  border-radius: 30px 0 0 30px;
  cursor: pointer;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.13);
  font-size: 12px;
  z-index: 300;
  transition: 0.25s;
  border: 3px solid #d0d0d0;
  border-right-width: 0px;
}
.three-column-layout > .first-column .switcher::after {
  content: "\e8c7";
  display: block;
  text-align: center;
  font-family: FontAwesome;
  color: #e5e5e5;
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 20px;
}
.three-column-layout > .first-column.away {
  width: 0;
  transition: 0.25s;
}
.three-column-layout > .first-column.away .switcher {
  right: -25px;
  border-radius: 0 30px 30px 0;
  background: rgba(0,0,0,0.25);
  border-right-width: 3px;
  border-left-width: 3px;
}
.three-column-layout > .first-column.away .switcher::after {
  content: "\e8c8";
}
.three-column-layout > .first-column .pane {
  bottom: 0;
}
.three-column-layout > .first-column .pane .body {
  min-height: 50px;
}
.three-column-layout > .right-column {
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 250px;
  border-left: solid 1px #dadada;
  transition: 0.25s;
  background: linear-gradient(to right, #e5e5e5 0%, #f2f2f2 7%);
}
.three-column-layout > .right-column.away {
  left: 0px;
  transition: 0.25s;
}
.three-column-layout > .right-column > .view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.column-layout > .body {
  top: 0;
}
.column-layout > .left-column {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  border-right: 1px solid #c8c8c8;
  box-shadow: inset 1px 1px 10px rgba(0,0,0,0.73);
  transition: 0.25s;
  padding-top: 50px;
  z-index: 10;
}
.column-layout > .left-column > .view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
.column-layout > .left-column .switcher {
  width: 25px;
  height: 40px;
  background: rgba(0,0,0,0.71);
  position: absolute;
  right: 0px;
  top: 7px;
  border-radius: 30px 0 0 30px;
  cursor: pointer;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.13);
  font-size: 12px;
  z-index: 300;
  transition: 0.25s;
  border: 3px solid #d0d0d0;
  border-right-width: 0px;
}
.column-layout > .left-column .switcher::after {
  content: "\e8c7";
  display: block;
  text-align: center;
  font-family: FontAwesome;
  color: #e5e5e5;
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 20px;
}
.column-layout > .left-column.away {
  width: 0;
  transition: 0.25s;
}
.column-layout > .left-column.away .switcher {
  right: -25px;
  border-radius: 0 30px 30px 0;
  background: rgba(0,0,0,0.25);
  border-right-width: 3px;
  border-left-width: 3px;
}
.column-layout > .left-column.away .switcher::after {
  content: "\e8c8";
}
.column-layout > .left-column .pane {
  bottom: 0;
  padding-top: 50px;
}
.column-layout > .left-column .pane .head {
  color: #5d5d5d;
  font-size: 22px;
  padding: 5px 15px;
  text-align: left;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 60%, rgba(0,0,0,0.51));
  text-shadow: #000 0 2px 2px;
}
.column-layout > .left-column .pane .body {
  min-height: 50px;
}
.column-layout > .right-column {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 300px;
  border-left: solid 1px #999;
  transition: 0.25s;
}
.column-layout > .right-column.away {
  left: 0;
  transition: 0.25s;
}
.column-layout > .right-column > .view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.top-menu {
  background-color: transparent;
  border: none;
  z-index: 1001;
}
.top-menu.btn-group {
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 0 0 10px 10px;
}
.top-menu.btn-group>.btn:first-child {
  border-radius: 0 0 0 10px;
}
.top-menu.btn-group>.btn:last-child {
  border-radius: 0 0 10px 0;
}
.top-menu.btn-group .button,
.top-menu.btn-group button,
.top-menu.btn-group .btn {
  padding: 5px 15px;
  font-size: 15px;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0));
  border-top: none;
}
.top-menu.btn-group.active {
  background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0));
  background-color: #f5f5f5;
}
.section-menu {
  position: relative;
  background: transparent;
  z-index: 3001;
  margin: 0 auto;
}
.section-menu.btn-group {
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 10px 10px 0 0;
}
.section-menu.btn-group>.btn:first-child {
  border-radius: 10px 0 0 0;
}
.section-menu.btn-group>.btn:last-child {
  border-radius: 0 10px 0 0;
}
.section-menu.btn-group .button,
.section-menu.btn-group button,
.section-menu.btn-group .btn {
  font-family: 'triunity condensed bold', sans-serif;
  margin-right: 0;
  background-color: #f5f5f5;
  color: #333;
  background-image: linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0));
}
.section-menu.btn-group .button.btn-inverse,
.section-menu.btn-group button.btn-inverse,
.section-menu.btn-group .btn.btn-inverse {
  background-image: linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0));
}
.section-menu.btn-group .button.btn-inverse:hover,
.section-menu.btn-group button.btn-inverse:hover,
.section-menu.btn-group .btn.btn-inverse:hover {
  background: #999;
  transition: background 0.25s;
}
.section-menu.btn-group .button.active,
.section-menu.btn-group button.active,
.section-menu.btn-group .btn.active {
  background: linear-gradient(to top, rgba(0,0,0,0.45), rgba(0,0,0,0));
  background-color: #fff;
  box-shadow: inset 0 2px 6px rgba(0,0,0,0.6);
  border: 1px solid #6d6d6d;
}
.inner-menu {
  border-bottom: solid 1px #636262;
  z-index: 900;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.inner-menu.btn-group>.btn:last-child {
  border-radius: 0 0 10px 0;
}
.inner-menu.btn-group>.btn:first-child {
  border-radius: 0 0 0 10px;
}
.inner-menu.btn-group .btn {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0));
  border: 1px solid rgba(255,255,255,0.85);
  border-bottom-color: #b3b3b3;
  padding: 3px 15px;
}
.inner-menu.btn-group .btn.active {
  background: linear-gradient(to top, rgba(0,0,0,0.45), rgba(0,0,0,0));
  background-color: #fff;
  box-shadow: inset 0 2px 6px rgba(0,0,0,0.6);
  border: 1px solid #6d6d6d;
}
.with-inner-menu.pane>.body {
  top: 0;
}
.pager-loader {
  background: url("/src/images/loaders/horizontal.gif") 50% 50% no-repeat;
  width: 26px;
  height: 26px;
  margin: 5px auto;
  border-radius: 50%;
  overflow: hidden;
}
.info-tool {
  padding: 10px;
}
.mobileHeight {
  height: 550px;
}
@media (max-width: 767px) {
  .column-layout .row-fluid {
    width: 100%;
  }
  .column-layout [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
  .btn-success.span12[style] {
    border-radius: 0px 0px 4px 20px !important;
    font-size: 16px !important;
  }
  #password[style] {
    border-radius: 0px !important;
  }
  #usid[style] {
    border-radius: 4px 10px 0 0 !important;
  }
}
@media (max-width: 600px) {
  .hideSmall {
    display: none;
  }
  .mobileHeight {
    max-height: 110vw;
  }
}
.mobile #uvTab {
  display: none;
}
.mobile #hidden {
  display: inline-block;
}
.mobile #login {
  width: 250px;
  top: 25%;
  position: fixed;
  left: 15px;
  margin-left: 0;
}
.mobile .inner-menu {
  position: absolute;
  bottom: 15px;
  height: 40px;
  top: inherit;
}
.mobile .image-back {
  position: fixed;
  width: 100%;
  height: 100%;
}
.mobile .with-inner-menu.pane>.body {
  top: 0;
}
.mobile .pane > .head {
  fixed: left 0 right 0;
}
.mobile .mobilehide {
  display: none;
}
.mobile .filter {
  top: 38px;
}
.mobile .filter .infoBubble {
  display: none;
}
.mobile .ilri .user-details .filter {
  top: 78px;
}
.mobile .student .details {
  float: none;
  padding: 10px 0 0 10px;
}
.mobile .student .goals {
  margin-left: 0px;
}
.mobile .todo.filter-container {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  padding: 0;
  height: 0;
  transition: 0.5s;
}
.mobile .todo.filter-container .switch {
  position: absolute;
  color: #fff;
  top: -15px;
  height: 15px;
  width: 40px;
  left: 50%;
  margin-left: -20px;
  background: rgba(0,0,0,0.5);
  border-radius: 5px 5px 0 0;
}
.mobile .todo.filter-container .switch::before {
  content: "\e8c9";
  font-family: FontAwesome;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: -2px;
}
.mobile .todo.filter-container.opened {
  padding: 5px;
  height: inherit;
}
.mobile .todo.filter-container.opened .switch::before {
  content: "\e8ca";
}
.mobile .todo.filter-container .title {
  font-size: 12px;
}
.mobile .todo.filter-container .filter {
  background: none;
  padding: 0;
  top: 5px;
  right: 5px;
  border-bottom: none;
  position: absolute;
}
.mobile .todo.filter-container select {
  display: inline-block;
  width: 90px !important;
  font-size: 10px;
  height: 20px;
  line-height: 18px;
  -webkit-appearance: initial;
  padding-left: 5px;
}
.mobile .journal-filter-item {
  float: left;
  margin-right: 3px;
}
.mobile .selected .gray {
  border-color: #00c800;
}
.mobile #header {
  height: 38px;
  fixed: top 0 left 0 right0;
}
.mobile #header .user {
  top: 3px;
  left: 5px;
}
.mobile #header .user .name {
  margin-left: 38px;
  font-size: 13px;
  margin-top: 8px;
}
.mobile #header .user .image-bg {
  height: 31px;
  width: 31px;
}
.mobile #header .user .image {
  height: 30px;
  width: 30px;
}
.mobile #header .user .image .loader {
  height: 31px;
  width: 31px;
}
.mobile #header .user .image img {
  height: 25px;
  width: 25px;
}
.mobile #header .school {
  display: none;
}
.mobile #footer {
  position: fixed;
  height: 15px;
  line-height: 12px;
}
.mobile #footer .donut {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  margin: 2px 5px 0 0;
}
.mobile .section-menu {
  display: none;
}
.mobile #root {
  top: 38px;
  bottom: 15px;
  position: absolute;
  overflow: visible;
}
.mobile #root .column-layout .widgets {
  display: none;
}
.mobile #root .column-layout .right-column {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-left: none;
  border-top: 1px solid #8f8f8f;
}
.mobile #root .column-layout .right-column > .view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  top: 40px;
}
.mobile #root .column-layout .right-column .inner-menu {
  top: 0;
}
.mobile #root .column-layout .right-column .pane {
  bottom: 0;
}
.mobile #navigation,
.mobile #mainNavigation {
  position: absolute;
  right: 0px;
  top: 38px;
  bottom: 0px;
  width: 0px;
  background: #353535;
  overflow: hidden;
  z-index: 20000;
}
.mobile #navigation ul a.active,
.mobile #mainNavigation ul a.active {
  background: #969696;
  color: #2f2f2f !important;
}
.mobile #navigation.opened,
.mobile #mainNavigation.opened {
  transition: 0.25s;
  width: 300px;
}
#print-frame {
  width: 100%;
  border: none;
}
@font-face {
  font-family: 'triunity bold';
  src: url("/src/fonts/triunity-bold-webfont.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
@font-face {
  font-family: 'triunity';
  src: url("/src/fonts/triunity-webfont.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
@font-face {
  font-family: 'triunity condensed';
  src: url("/src/fonts/triunity-condensed-webfont.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -1.5px;
}
@font-face {
  font-family: 'triunity condensed bold';
  src: url("/src/fonts/triunity-condensedbold-webfont.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -1px;
}
.Toastify__toast {
  background-color: rgba(245,245,245,0.85);
  color: #333;
  opacity: 0.95;
  font-size: 12px;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.7);
  border-radius: 2px;
  padding: 0 20px;
}
.Toastify__toast h2 {
  font-size: 16px;
  margin: 0;
}
.Toastify__toast p {
  margin-top: -6px;
}
.Toastify__toast button {
  display: none;
}
input.invalid,
.react-select-input.invalid,
select.invalid,
textarea.invalid {
  border-color: #df1b17 !important;
  background: #fff8f1 !important;
}
input.invalid:focus,
.react-select-input.invalid:focus,
select.invalid:focus,
textarea.invalid:focus {
  box-shadow: 0 0 6px #d59392 !important;
}
label {
  margin: 0;
}
/**
* Fixes print cutting off at one page
*/
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}
