.printContainer {
  @page {
    size: letter;
    margin: 0.5in;
    font-family: Verdana, sans-serif;
  }
  table {
    width: 100% !important;
    border-collapse: collapse !important;
    margin-bottom: 20px;
  }
  table td {
    padding: 0 !important;
    font-size: inherit !important;
    font-family: inherit !important;
  }
  table.list thead td {
    font-weight: bold !important;
    border-bottom: 5px double #808080 !important;
  }
  table.list tbody td {
    border-bottom: 1px solid #c4c4c4 !important;
  }
  h1, h2, h3 {
    font-family: Ubuntu, Helvetica, sans-serif !important;
    line-height: 40px !important;
    border: none !important;
  }
  h1 {
    font-size: 24px !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 10px !important;
  }
  h2 {
    color: #4d4dd3;
    margin-bottom: 5px !important;
    border-bottom: none !important;
    font-weight: bold !important;
  }
  h3 {
    margin-bottom: 3px !important;
    padding-bottom: 3px !important;
  }
  h4 {
    margin-bottom: 6px !important;
  }
  p {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  [class~=frame] {
    border-bottom: 1px solid #d5d5d5 !important;
  }
  [class~=vtop] {
    vertical-align: top !important;
  }
  [class~=text-center] {
    text-align: center !important;
  }
  [class~=vbottom] {
    vertical-align: bottom !important;
  }
  [class~=underline] {
    border-bottom: 1px solid #808080 !important;
  }
  [class~=nowrap] {
    white-space: nowrap !important;
  }
  [class~=icon-check] {
    font-family: FontAwesome !important;
    font-weight: normal !important;
    font-style: normal !important;
    width: 20px !important;
    height: 20px !important;
  }
  [class~=html] h1 {
    font-size: 16px !important;
    line-height: 16px !important;
    color: #555 !important;
    border: none !important;
    margin: 10px 0 !important;
    padding: 0 !important;
  }
  [class~=html] h2 {
    font-size: 14px !important;
    line-height: 14px !important;
    color: #555 !important;
    border: none !important;
    margin: 10px 0 !important;
    padding: 0 !important;
  }
  [class~=html] h3 {
    font-size: 13px !important;
    line-height: 13px !important;
    color: #555 !important;
    font-style: italic !important;
    font-weight: bold !important;
    border: none !important;
    margin: 10px 0 !important;
    padding: 0 !important;
  }
  [class~=html] p:last-child {
    margin-bottom: 0 !important;
  }
  [class~=span4] {
    width: 200px !important;
    float: left !important;
  }
  [class~=span8] {
    float: left !important;
    width: 510px !important;
    margin-left: 10px !important;
  }
  [class~=pack] {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    border: none !important;
  }
  [class~=infoBubble] {
    background: none !important;
    box-shadow: none !important;
    padding: 0 10px !important;
  }
  [class~=pack] > [class~=header] {
    padding:0 !important;
    background:none !important;
  }
  [class~=pack] [class~=name]{
    border-bottom:1px solid #8EC43D !important;
  }
  [class~=pack] [class~=body], [class~=smallText]{
    font-size:10px !important;
    line-height:13px !important;
  }
  [class~=plan-overview] [class~=graph], [class~=analytics] [class~=graph]{

    background:none !important;
    border:1px solid #C4C4C4 !important;
    padding-bottom:0 !important;
    -webkit-border-radius: 4px 20px !important;
    border-radius: 4px 20px !important;
  }
  [class~=row]{
    margin-bottom:20px !important;
  }
  [class~=userName]{
    float: left !important;
    margin-top: 20px !important;
    margin-left: 10px !important;
    font-size: 20px !important;
    font-family: triunity !important;
  }
  [class~=largeText]{
    font-size:16px !important;
    font-family:triunity !important;
  }
}
